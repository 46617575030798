var flexSpot = document.querySelector('.flex-spot.comparison');

var addWrappers = function addWrappers() {
  var div = document.createElement('div');
  div.classList.add('header-container');
  var elementsContainer = document.querySelector('.flex-spot.comparison .elements');
  elementsContainer.prepend(div);
  var elements = Array.from(flexSpot.querySelectorAll('.element'));
  var dataNumColsAttribute = flexSpot.getAttribute('data-num-cols');
  var elementsToWrap = elements.slice(0, dataNumColsAttribute);
  elements.forEach.call(elementsToWrap, function (element) {
    div.appendChild(element);
  });
  elements.splice(0, dataNumColsAttribute);

  if (elements[0].dataset.tag === 'comparison category') {
    div.classList.add('no-border');
  }

  elements.forEach(function (element, index) {
    var headerItemToWrap = element.querySelector('[data-tag*=header]');
    var firstElement = element.querySelector('[data-tag*=header] + .element__item');
    var infoButton = element.querySelector('[data-tag*=info-button]');
    var infoBox = element.querySelector('[data-tag*=info-box]');
    var headerDiv = document.createElement('div');
    var closeButtonDiv = document.createElement('div');
    var closeButtonContainer = document.createElement('div');
    var modalDiv = document.createElement('div');
    var modalDivContent = document.createElement('div');

    if (infoBox) {
      modalDivContent.innerHTML = infoBox.innerHTML;
      infoBox.innerHTML = '';
    }

    headerDiv.classList.add('header-item');
    closeButtonDiv.classList.add('close-button');
    closeButtonContainer.classList.add('close-button-container');
    modalDivContent.classList.add('modal-content');
    modalDiv.classList.add('modal-container');
    closeButtonDiv.prepend(closeButtonContainer);
    modalDiv.prepend(modalDivContent);
    modalDiv.prepend(closeButtonDiv);
    element.prepend(headerDiv);
    headerDiv.appendChild(headerItemToWrap);

    if (headerItemToWrap.textContent.trim() === '' || !firstElement) {
      // empty header item or no elements => meaning category
      $(headerItemToWrap).closest('.element').prev().addClass('no-border');
    }

    if (infoButton) headerItemToWrap.appendChild(infoButton);
    var readMore = document.createElement('button');

    if (infoBox) {
      headerDiv.appendChild(infoBox);
      readMore.style.display = 'none';
      readMore.style.visibility = 'hidden';
      readMore.textContent = 'Read more'; // translation ???

      readMore.classList.add("read-more-button".concat(index));
      modalDiv.append(readMore);
      readMore.addEventListener('click', function (event) {
        event.preventDefault();
        modalDiv.classList.add('open');
        infoBox.classList.add('open');
        readMore.style.display = 'none';
      });
      infoBox.appendChild(modalDiv);
    }

    if (infoButton) {
      infoButton.addEventListener('click', function () {
        var isMobile = window.matchMedia('screen and (max-width: 767px)').matches;
        var activeElements = Array.from(document.querySelectorAll('.active'));
        activeElements.forEach(function (activeElement) {
          activeElement.classList.remove('active');
        });
        var openElements = Array.from(document.querySelectorAll('.open'));
        openElements.forEach(function (openElement) {
          openElement.classList.remove('open');
        });

        if (!isMobile) {
          setTimeout(function () {
            if (modalDivContent.clientHeight < modalDivContent.scrollHeight) {
              readMore.style.display = 'block';
              readMore.style.visibility = 'visible';
              modalDivContent.style.overflow = 'hidden';
            }
          }, 0);
        } else {
          readMore.style.display = 'none';
        }

        infoBox.classList.add('active');
        headerDiv.classList.add('active');

        if (isMobile) {
          document.body.style.overflow = 'hidden';
        }
      });
      infoBox.addEventListener('click', function () {
        infoBox.classList.remove('active');
        headerDiv.classList.remove('active');
        modalDiv.classList.remove('open');
        infoBox.classList.remove('open');
        document.body.style.overflow = 'unset';
      });
      modalDiv.addEventListener('click', function (e) {
        e.stopPropagation();
      });
      closeButtonDiv.addEventListener('click', function () {
        infoBox.classList.remove('active');
        infoBox.classList.remove('open');
        headerDiv.classList.remove('active');
        modalDiv.classList.remove('open');
        readMore.style.display = 'block';
        document.body.style.overflow = 'unset';
      });
    }
  });
};

if (flexSpot) {
  (function () {
    addWrappers();
  })();
}