(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    $('.richtext-submenu').each(function () {
      var $this = $(this),
          offset = $this.offset(),
          offsetTop = offset.top,
          curScroll,
          $h3 = $('h3', $this),
          $h4 = $('h4', $this),
          $p = $('p', $this),
          $a = $('a', $this),
          theURL = document.location.href.replace('http://', '').replace('https://', ''),
          activeUrlLength = 0,
          $activeLink = null;
      window.DGS.Justify.getInstance().apply($('.richtext-submenu-columns', $this), 'div');
      $a.each(function () {
        var tempURL = $(this).attr('href').replace('http://', '').replace('https://', '');

        if (theURL.indexOf(tempURL) > 0 && tempURL.length > activeUrlLength) {
          activeUrlLength = tempURL.length;
          $activeLink = $(this);
        }
      });
      if ($activeLink != null) $activeLink.addClass('active');
      $h3.each(function () {
        var $hThis = $(this);

        if ($hThis.text().trim() == "") {
          $hThis.remove();
        }
      });
      $h4.each(function () {
        var $hThis = $(this);

        if ($hThis.text().trim() == "") {
          $hThis.remove();
        }
      });
      $p.each(function () {
        var $hThis = $(this),
            text = $hThis.text().trim();

        if (text == "") {
          $hThis.remove();
        }
      });
      $('.submenu-expander', $this).on('click', function (e) {
        e.preventDefault();
        $('.richtext-submenu-columns', $this).slideToggle(200);
        $this.toggleClass('expanded');
      });
      $('.submenu-bar > .title-link', $this).on('click', function (e) {
        $('html, body').animate({
          scrollTop: 0
        }, "1000");
      });

      function handleScroll(windowScroll) {
        curScroll = $this.data('curscroll');

        if (windowScroll > offsetTop) {
          $this.addClass('scrolled');

          if (curScroll != undefined && curScroll > windowScroll && $this.hasClass('expanded')) {
            $this.removeClass('expanded');
            $('.richtext-submenu-columns', $this).slideUp(200);
          }
        } else {
          $this.removeClass('scrolled');
        }

        $this.data('curscroll', windowScroll);
      }

      window.DGS.Scroller.getInstance().register(handleScroll);
      $('.richtext-submenu-column h3', $this).on('click', function () {
        if (window.matchMedia('(max-width: 900px)').matches) {
          $('.richtext-submenu-column', $this).css('display', 'none');
          var $thisH3 = $(this),
              $container = $thisH3.closest('.richtext-submenu-columns'),
              $elements = $('.richtext-submenu-column > *', $container),
              shouldStart = false,
              shouldBreak = false,
              isOpen = $thisH3.hasClass('open'),
              $parent = $thisH3.parent();
          if (!isOpen) $('.richtext-submenu-column', $this).css('display', 'none');else $('.richtext-submenu-column', $this).css('display', '');
          $parent.css('display', '');
          $elements.each(function () {
            var $this = $(this),
                elementName = $this.prop('nodeName').toLowerCase(),
                isH3 = elementName == "h3",
                text = $this.text();

            if (!shouldStart) {
              if ($this[0] == $thisH3[0]) {
                shouldStart = true;
                $this.toggleClass('open');
                $this.removeClass('hide');
              } else if (!isH3) {
                $this.hide(100);
              } else {
                $this.removeClass('open');
                if (!isOpen) $this.addClass('hide');else $this.removeClass('hide');
              }
            } else {
              if (!shouldBreak) {
                if (!isH3) {
                  $this.toggle(100);
                } else if (text.trim().length > 0) {
                  shouldBreak = true;
                  $this.removeClass('open');
                  if (!isOpen) $this.addClass('hide');else $this.removeClass('hide');
                }
              } else if (!isH3) {
                $this.hide(100);
              } else {
                $this.removeClass('open');
                if (!isOpen) $this.addClass('hide');else $this.removeClass('hide');
              }
            }
          });
        }
      });
      window.DGS.Resizer.getInstance().register(function () {
        if (!window.matchMedia('(max-width: 900px)').matches) {
          $('.richtext-submenu').each(function () {
            var $this = $(this);
            $('.richtext-submenu-column h3', $this).removeClass('open');
            $('.richtext-submenu-column > *:not(h3)', $this).css('display', '');
          });
        } else {
          $('.richtext-submenu').each(function () {
            var $this = $(this);
            $('.richtext-submenu-column h3', $this).removeClass('open');
            $('.richtext-submenu-column > *:not(h3)', $this).css('display', '');
          });
        }
      });
    });
  });
})(jQuery);