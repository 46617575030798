$(function () {
  'use strict';

  window.DGS.OnLoad.getInstance().register(function () {
    $('#content .news-list-spot').each(function () {
      var $this = $(this);
      var $newsItem = $('li', $this);
      $newsItem.each(function () {
        var $this = $(this),
            date = $('.news-date', $this).detach(),
            image = $('.news-image', $this);
        date.insertAfter(image);
        $this.find('.news-date, .news-header, .news-location, .news-text, .news-link').wrapAll('<div class="news-wrapper" />');
      });
    });
  });
});