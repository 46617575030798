(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var grid = 5;
    var maxHeight = 0;
    selectGrid();
    resizeHeight(grid);
    $(window).resize(function () {
      selectGrid();
      resizeHeight(grid);
    });

    function selectGrid() {
      var width = $(".tiles").width();

      if (width < 1020) {
        if (grid != 3) {
          clearCoordinates();
          grid = 3;
        }
      }

      if (width < 1400 && width >= 1020) {
        if (grid != 4) {
          clearCoordinates();
          grid = 4;
        }
      }

      if (width >= 1400) {
        if (grid != 5) {
          clearCoordinates();
          grid = 5;
        }
      }

      $(".tiles").addClass("grid" + grid); //Add classes from given data attribute

      $(".tiles div").each(function () {
        $(this).addClass($(this).data("grid" + grid));
      });
    }

    function clearCoordinates() {
      $(".tiles div").removeClass("column-1");
      $(".tiles div").removeClass("column-2");
      $(".tiles div").removeClass("column-3");
      $(".tiles div").removeClass("column-4");
      $(".tiles div").removeClass("column-5");
      $(".tiles div").removeClass("row-1");
      $(".tiles div").removeClass("row-2");
      $(".tiles div").removeClass("row-3");
      $(".tiles div").removeClass("row-4");
      $(".tiles div").removeClass("row-5");
      $(".tiles div").removeClass("row-6");
      $(".tiles div").removeClass("hide-tile");
      $(".tiles").removeClass("grid3");
      $(".tiles").removeClass("grid4");
      $(".tiles").removeClass("grid5");
    }

    function resizeHeight(gridSize) {
      var width = $(".width-1").width();
      $(".height-1").css({
        "height": width + "px"
      });
      $(".height-2").css({
        "height": width * 2 + "px"
      });
      $(".row-1").css({
        "top": "0px"
      });
      $(".row-2").css({
        "top": width + "px"
      });
      $(".row-3").css({
        "top": width * 2 + "px"
      });
      $(".row-4").css({
        "top": width * 3 + "px"
      });
      $(".row-5").css({
        "top": width * 4 + "px"
      });
      $(".row-6").css({
        "top": width * 5 + "px"
      });
      $(".component.tile-spot").each(function () {
        var currentSpot = $(this);
        maxHeight = 0;
        $(".tiles .tile", currentSpot).each(function () {
          if ($(this).hasClass("row-6")) {
            if ($(this).hasClass("height-2")) {
              checkHeight(7);
            } else {
              checkHeight(6);
            }
          } else if ($(this).hasClass("row-5")) {
            if ($(this).hasClass("height-2")) {
              checkHeight(6);
            } else {
              checkHeight(5);
            }
          } else if ($(this).hasClass("row-4")) {
            if ($(this).hasClass("height-2")) {
              checkHeight(5);
            } else {
              checkHeight(4);
            }
          } else if ($(this).hasClass("row-3")) {
            if ($(this).hasClass("height-2")) {
              checkHeight(4);
            } else {
              checkHeight(3);
            }
          } else if ($(this).hasClass("row-2")) {
            if ($(this).hasClass("height-2")) {
              checkHeight(3);
            } else {
              checkHeight(2);
            }
          } else if ($(this).hasClass("row-1")) {
            if ($(this).hasClass("height-2")) {
              checkHeight(2);
            } else {
              checkHeight(1);
            }
          }
        });
        var cc = $(".component-content", currentSpot);
        cc.css({
          "height": width * maxHeight + "px"
        });
      });
    }

    function checkHeight(height) {
      if (height > maxHeight) {
        maxHeight = height;
      }
    }
  });
})(jQuery);