function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Import this file to enable default GTM tracking
var commonTracking = {
  event: 'GAevent',
  eventCategory: 'HCL'
};

var USE_LOCATION = function USE_LOCATION(accepted) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Search – use location',
    eventLabel: accepted ? 'Accepted' : 'Rejected'
  });
};

var SEARCH_PHRASE = function SEARCH_PHRASE(searchPhrase) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Search',
    eventLabel: searchPhrase
  });
};

var HIGHLIGHT_FROM_LIST = function HIGHLIGHT_FROM_LIST(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Highlight Clinic - List',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var HIGHLIGHT_FROM_MAP = function HIGHLIGHT_FROM_MAP(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Highlight Clinic - Map',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var CLINIC_CLICK = function CLINIC_CLICK(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic Info',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var SUBMIT_FORM = function SUBMIT_FORM(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - submit',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var GET_DIRECTIONS = function GET_DIRECTIONS(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Driving instructions',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var PHONE_NUMBER = function PHONE_NUMBER(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Phone',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var PHONE_NUMBER_FROM_LIST = function PHONE_NUMBER_FROM_LIST(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic list - Phone',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var WEBSITE_LINK = function WEBSITE_LINK(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Website',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var EMAIL_LINK = function EMAIL_LINK(clinicName, clinicPostCode) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic - Email',
    eventLabel: "".concat(clinicName, " - ").concat(clinicPostCode)
  });
};

var OPEN_FILTER_LIST = _objectSpread(_objectSpread({}, commonTracking), {}, {
  eventAction: 'Clinic Filter - filter opened'
});

var ADD_FILTER = function ADD_FILTER(filterName) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic Filter - filter added',
    eventLabel: filterName
  });
};

var REMOVE_FILTER = function REMOVE_FILTER(filterName) {
  return _objectSpread(_objectSpread({}, commonTracking), {}, {
    eventAction: 'Clinic Filter - filter removed',
    eventLabel: filterName
  });
};

var track = function track(trackingObj) {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer().push(trackingObj);
};

window.DGS.Event.getInstance().on('trackLocationsUseLocation', function (accepted) {
  track(USE_LOCATION(accepted));
});
window.DGS.Event.getInstance().on('trackLocationsSearchPhrase', function (searchPhrase) {
  track(SEARCH_PHRASE(searchPhrase));
});
window.DGS.Event.getInstance().on('trackLocationsHighlightFromList', function (clinicName, clinicPostCode) {
  track(HIGHLIGHT_FROM_LIST(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsHighlightFromMap', function (clinicName, clinicPostCode) {
  return track(HIGHLIGHT_FROM_MAP(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsClinicClick', function (clinicName, clinicPostCode) {
  return track(CLINIC_CLICK(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('submitFormLocationsClinicClick', function (clinicName, enquiryReason) {
  return track(SUBMIT_FORM(clinicName, enquiryReason));
});
window.DGS.Event.getInstance().on('trackLocationsGetDirections', function (clinicName, clinicPostCode) {
  return track(GET_DIRECTIONS(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsPhoneNumber', function (clinicName, clinicPostCode) {
  return track(PHONE_NUMBER(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsPhoneNumberList', function (clinicName, clinicPostCode) {
  return track(PHONE_NUMBER_FROM_LIST(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsWebsiteLink', function (clinicName, clinicPostCode) {
  return track(WEBSITE_LINK(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsEmailLink', function (clinicName, clinicPostCode) {
  return track(EMAIL_LINK(clinicName, clinicPostCode));
});
window.DGS.Event.getInstance().on('trackLocationsOpenFilterList', function () {
  track(OPEN_FILTER_LIST);
});
window.DGS.Event.getInstance().on('trackLocationsAddFilter', function (filterName) {
  return track(ADD_FILTER(filterName));
});
window.DGS.Event.getInstance().on('trackLocationsRemoveFilter', function (filterName) {
  return track(REMOVE_FILTER(filterName));
});