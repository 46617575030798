(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    window.DGS.EqualHeight.getInstance().register($('.submenu-spot .component-content > ul > li'));
    $('.submenu-expander').on('click', function (e) {
      e.preventDefault();
      $('.submenu-spot ul').slideToggle(200);
      $('.submenu-spot').toggleClass('expanded');
      window.DGS.EqualHeight.getInstance().trigger($('.submenu-spot .component-content > ul > li'));
    });
    $('.submenu-bar > .title-link').on('click', function (e) {
      $('html, body').animate({
        scrollTop: 0
      }, "1000");
    });
    $('.submenu-spot').each(function () {
      var thisElem = $(this),
          offset = thisElem.offset(),
          offsetTop = offset.top,
          curScroll;

      function handleScroll() {
        curScroll = $(thisElem).data('curscroll');
        var windowScroll = $(window).scrollTop();

        if (windowScroll > offsetTop) {
          $(thisElem).addClass('scrolled');

          if (curScroll != undefined && curScroll > windowScroll && $(thisElem).hasClass('expanded')) {
            $('.submenu-spot').removeClass('expanded');
            $('.submenu-spot ul').slideUp(200);
          }
        } else if (windowScroll < offsetTop) {
          $(thisElem).removeClass('scrolled');
        } else {
          $(thisElem).removeClass('scrolled');
        }

        $(thisElem).data('curscroll', windowScroll);
      }

      $(window).on('scroll', handleScroll);
      handleScroll();
    });
  });
})(jQuery);