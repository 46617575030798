(function ($) {
  "use strict";

  window.DGS.OnLoad.getInstance().register(function () {
    // browser window scroll (in pixels) after which the "back to top" link is shown
    var offset = 300,
        //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
    offset_opacity = 1200,
        //duration of the top scrolling animation (in ms)
    scroll_top_duration = 700,
        //grab the "back to top" link
    $back_to_top = $('#wrapper > #header .cd-top'); //hide or show the "back to top" link

    window.DGS.Scroller.getInstance().register(function () {
      if ($(window).scrollTop() > offset) {
        $back_to_top.addClass('cd-is-visible');
      } else {
        $back_to_top.removeClass('cd-is-visible cd-fade-out');
      }

      if ($(window).scrollTop() > offset_opacity) {
        $back_to_top.addClass('cd-fade-out');
      }
    }); //smooth scroll to top

    $back_to_top.on('click', function (event) {
      event.preventDefault();
      $('body,html').animate({
        scrollTop: 0
      }, scroll_top_duration);
    });
  });
})(jQuery);