(function ($, DGS) {
  var consts = {
    DATA_RESPONSE_URL: "response-url",
    DATA_FIELD_NAME: "field-name",
    DATA_SORT_ORDER: "sort-order"
  },
      DataListSpotConfig = {
    resolveSortForField: function resolveSortForField(field, sortType) {
      return '';
    },
    decorateRowAtIndex: function decorateRowAtIndex(data, index, $row) {
      $row.addClass(index % 2 == 0 ? "data-list-spot__row-even" : "data-list-spot__row-odd");
    },
    fieldInfo: {},
    Event: {
      DATA_RESULT_READY: "dataResultReady",
      DATA_RESULT_EMPTY: "dataResultEmpty",
      DATA_LIST_PRE_SEARCH: "dataListPreSearch",
      DATA_SEARCH_QUERY_READY: "dataSearchQueryReady",
      DATA_REQUEST_BUILD_QUERY: "dataRequestBuildQuery",
      DATA_REQUEST_BUILD_ADV_QUERY: "dataRequestBuildAdvQuery",
      DATA_REQUEST_INITIAL_BUILD_QUERY: "dataRequestInitialBuildQuery",
      DATA_REQUEST_INITIAL_BUILD_ADV_QUERY: "dataRequestInitialBuildAdvQuery",
      DATA_REQUEST_INITIAL_RECEIVED: "dataRequestInitialReceived",
      DATA_SEARCH_SET_GLOBAL_FILTER: "dataSearchSetGlobalFilter",
      DATA_LIST_BUILT: "dataListBuilt"
    },
    initialRequests: {}
  },
      savedFilterObject = {},
      getConfigurationId = function getConfigurationId($dataList) {
    return $dataList.data("configuration");
  },
      getConfigForDataList = function getConfigForDataList($dataList) {
    var configId = getConfigurationId($dataList),
        script = $('script[type="application/json"]', $dataList),
        config = JSON.parse(script.html());
    return config;
  },
      checkFieldNameSortability = function checkFieldNameSortability(fieldName) {
    return DataListSpotConfig.fieldInfo.hasOwnProperty(fieldName) ? DataListSpotConfig.fieldInfo[fieldName].IsSortable : false;
  },
      showContent = function showContent($container) {
    TweenMax.fromTo($container, 0.3, {
      alpha: 0
    }, {
      alpha: 1,
      ease: Power2.easeIn
    });
  },
      showRows = function showRows($rows) {
    TweenMax.staggerFromTo($rows, .2, {
      alpha: 0
    }, {
      alpha: 1,
      ease: Power2.easeIn
    }, .05);
  },
      initDataListSpot = function initDataListSpot() {
    $(".component.data-list-spot").each(function () {
      var $dataList = $(this),
          configurationId = getConfigurationId($dataList),
          config = getConfigForDataList($dataList),
          $componentContent = $(".component-content", $dataList),
          $headerContainer,
          $itemsContainer; // IF IS COLLAPSED. ADD LABEL

      if (isCollapsed($dataList)) {
        var toggleCollapse = function toggleCollapse() {
          $dataList.toggleClass('open');
          $('.data-list-spot-search', $componentContent).val('');
          $('.data-list-spot__row').show();

          if ($componentContent.hasClass('open')) {
            setTimeout(function () {
              DGS.PageClick.getInstance().register(toggleCollapse);
            }, 0);
          } else {
            DGS.PageClick.getInstance().remove(toggleCollapse);
          }
        };

        $componentContent.append('<div class="collapsed-outer-container"><div class="collapsed-inner-container"><div class="data-list-selected-value">Sold to...</div></div></div>');
        var $dataListTitle = $('.data-list-title', $dataList);

        if ($dataListTitle.length) {
          $dataListTitle.remove();
          $('.data-list-selected-value', $componentContent).before($dataListTitle);
        }

        $componentContent.click(function (e) {
          e.stopPropagation();
          toggleCollapse();
        });
      } // IF IS SEARCHABLE. ADD SEARCH


      if (isSearchable($dataList)) {
        $componentContent.append('<div class="search-outer-container"><div class="search-inner-container"><input type="text" class="data-list-spot-search" placeholder="Search account no. or name"/></div></div>');
        $('.data-list-spot-search', $componentContent).keyup(function (event) {
          var query = $(this).val().toLowerCase();
          $('.data-list-spot__row', $dataList).each(function () {
            var $this = $(this),
                $column = $('.data-list-spot__row__column span', $this),
                show = false;
            $column.each(function () {
              if ($(this).text().toLowerCase().indexOf(query) != -1) {
                show = true;
              }
            });
            if (show) $this.show();else $this.hide();
          });
        });
        $('input', $componentContent).click(function (e) {
          e.stopPropagation();
        });
      } // ADD HEADERS


      $componentContent.append('<div class="data-list-spot__table-container"><div class="data-list-spot__table headers"></div></div>');
      $headerContainer = $('.data-list-spot__table.headers', $dataList);
      buildHeaders($dataList, $headerContainer); // ADD ITEMS CONTAINER

      $componentContent.append('<div class="data-list-spot__table-container"><div class="data-list-spot__table items"></div></div>');
      $itemsContainer = $('.data-list-spot__table.items', $dataList); // SETUP EVENT LISTENERS - begin

      DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_RESULT_READY, function (identifier, data, resultURL) {
        if (config.endpoint && identifier == config.endpoint || identifier == configurationId) {
          if (resultURL && $dataList.data('result-url') !== resultURL) {
            return;
          }

          removeLoader($dataList);

          if (!buildRows($dataList, data, $itemsContainer)) {
            addViewAllComponent($dataList, $componentContent);
          }

          decorateCells($dataList);
          addLoadMoreComponent(config.endpoint, $dataList, data, $componentContent);
          DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_LIST_BUILT, configurationId, config.data);
        }
      });
      DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_RESULT_EMPTY, function (endpoint, resultURL) {
        if (endpoint == config.endpoint) {
          if (resultURL && $dataList.data('result-url') !== resultURL) {
            return;
          }

          removeLoader($dataList);

          if (!$('.data-list-spot__row', $dataList).length) {
            addEmptyResultComponent($dataList, $componentContent);
          }
        }
      });
      DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_LIST_PRE_SEARCH, function (endpoint) {
        if (endpoint == config.endpoint) {
          clearSort($dataList);
          clearRows($dataList);
          buildLoader($dataList);
        }
      });
      DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY, function (endpoint, fromDate, toDate, filterObject, queryJoinType, extraQuery) {
        if (endpoint == config.endpoint) {
          clearSort($dataList);
          clearRows($dataList);
          buildLoader($dataList);
          requestResultUrl($dataList, config.endpoint, fromDate, toDate, filterObject, queryJoinType, function (responseUrl) {
            if (extraQuery) {
              if (responseUrl.indexOf('?') === -1) {
                responseUrl += "?" + extraQuery;
              } else {
                responseUrl += "&" + extraQuery;
              }
            }

            saveResultUrlOnDataList($dataList, responseUrl);

            if (config.sortOrder) {
              if (responseUrl.indexOf('?') !== -1) {
                responseUrl += '&orderby=' + config.sortOrder;
              } else {
                responseUrl += '?orderby=' + config.sortOrder;
              }
            }

            requestResultData($dataList, config.endpoint, responseUrl, 0, true);
          });
        }
      });
      DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_SEARCH_SET_GLOBAL_FILTER, function (field, value) {
        savedFilterObject[field] = field + ' ' + value; // refresh list

        initialPopulation($dataList);
      }); // SETUP EVENT LISTENERS - end
      // INITIAL POPULATION - begin

      initialPopulation($dataList); // INITIAL POPULATION - end

      if (isCollapsed($dataList)) {
        setDefaultValue($dataList, config.data);
      }
    });
  },
      initialPopulation = function initialPopulation($dataList) {
    var configurationId = getConfigurationId($dataList),
        config = getConfigForDataList($dataList),
        initialRequestData;

    if (config.endpoint) {
      if (DataListSpotConfig.initialRequests.hasOwnProperty(config.endpoint)) {
        initialRequestData = DataListSpotConfig.initialRequests[config.endpoint];
        DGS.Event.getInstance().trigger(initialRequestData.queryType, initialRequestData.$searchSpot, config.endpoint);
      } else {
        getDataForConfiguredDateRange($dataList, config.endpoint);
      }
    } else if (config.data) {
      clearRows($dataList);
      DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_READY, configurationId, config.data);
    }
  },
      requestResultUrl = function requestResultUrl($dataList, requestUrl, from, to, filterObject, queryJoinType, callback) {
    if (from == null && to == null) {
      var dates = getConfiguredDateRange($dataList);
      from = dates.from;
      to = dates.to;
    }

    var tzoffset = new Date().getTimezoneOffset() * 60000,
        data = {
      from: new Date(from - tzoffset).toISOString().substring(0, 10).replace(/-/g, ""),
      to: new Date(to - tzoffset).toISOString().substring(0, 10).replace(/-/g, "")
    },
        config = getConfigForDataList($dataList),
        requestObject = $dataList.data('requestObject'),
        currentUUID = $dataList.data('uuid'),
        uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
    var jsonData = JSON.stringify(data);

    if (requestObject && requestObject.requestId == currentUUID) {
      try {
        requestObject.hasBeenActivelyAborted = true;
        requestObject.abort();
      } catch (error) {
        console.log(error);
      }
    }

    $dataList.data('uuid', uuid);
    requestObject = $.ajax({
      url: requestUrl,
      type: 'GET',
      data: {
        date: jsonData
      },
      beforeSend: function beforeSend(jqXHR, settings) {
        jqXHR.requestId = uuid;
      }
    }).then(function (data, textState, jqXHR) {
      if ($dataList.data('uuid') != jqXHR.requestId) return;
      var resultUrl = data,
          filter = Object.keys(filterObject).map(function (f) {
        return filterObject[f];
      }).join(" " + queryJoinType + " "),
          savedFilter = Object.keys(savedFilterObject).map(function (f) {
        return savedFilterObject[f];
      }).join(" and ");

      if (filter && filter.length > 0) {
        if (config.filter) filter += " and " + config.filter;
      } else {
        filter = config.filter;
      }

      if (savedFilter && savedFilter.length > 0) {
        if (filter && filter.length > 0) {
          filter += " and " + savedFilter;
        } else {
          filter = savedFilter;
        }
      }

      if (filter && filter.length > 0) {
        filter = filter.replace('(', '%28');
        filter = filter.replace(')', '%29');
        resultUrl += "?filter=" + filter;
      }

      callback.apply(null, [resultUrl]);
    }, function (jqXHR, textStatus, msg) {
      if (jqXHR.hasBeenActivelyAborted) return;
      if ($dataList.data('uuid') != jqXHR.requestId) return;

      switch (jqXHR.status) {
        case 401:
          window.location = config.renewLoginUrl;
          break;

        default:
          DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
          break;
      }
    });
    requestObject.requestId = uuid;
  },
      requestResultData = function requestResultData($dataList, requestUrl, resultUrl, iterationCount, clearRowsOnReady) {
    $dataList.data('result-url', resultUrl);
    var config = getConfigForDataList($dataList);

    if (resultUrl === null || iterationCount > 10) {
      // timeout
      DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
      return;
    }

    $.ajax({
      url: resultUrl,
      type: 'GET'
    }).then(function (data, textState, jqXHR) {
      switch (jqXHR.status) {
        case 204:
          setTimeout(function () {
            requestResultData($dataList, requestUrl, resultUrl, ++iterationCount, clearRowsOnReady);
          }, 500);
          break;

        case 200:
          if (clearRowsOnReady) clearRows($dataList);

          if (!data.hasOwnProperty('items') || data.items.length == 0) {
            DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl, resultUrl);
          } else {
            DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_READY, requestUrl, data, resultUrl);
          }

          break;
      }
    }, function (jqXHR, textState, msg) {
      if (clearRowsOnReady) clearRows($dataList);

      switch (jqXHR.status) {
        case 401:
          window.location = config.renewLoginUrl;
          break;

        default:
          DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_RESULT_EMPTY, requestUrl);
          break;
      }
    });
  },
      buildHeaders = function buildHeaders($dataList, $container) {
    var config = getConfigForDataList($dataList),
        columns = config ? config.fields : [],
        $dataListHeaders = $('<div class="data-list-spot__headers"></div>');
    columns.forEach(function (column) {
      var classes = ['data-list-spot__headers__header', column.fieldName],
          label = column.label;

      if (column.className) {
        classes.push(column.className);
      }

      if (column.hideOnMobile) {
        classes.push('hide-on-mobile');
      }

      if (column.hideOnTablet) {
        classes.push('hide-on-tablet');
      }

      if (checkFieldNameSortability(column.fieldName) && classes.indexOf('not-sortable') === -1) {
        classes.push('sortable');
      }

      var $header = $('<div class="' + classes.join(' ') + '"><span>' + label + '</span></div>');
      $header.data(consts.DATA_FIELD_NAME, column.fieldName);
      $dataListHeaders.append($header);
    });
    $container.append($dataListHeaders);
    $('.data-list-spot__headers__header.sortable', $container).click(function (e) {
      e.stopPropagation();
      var $this = $(this),
          $allHeaders = $('.data-list-spot__headers__header'),
          sortType = $this.hasClass('ordered-asc') ? 'ordered-desc' : 'ordered-asc',
          fieldName = $this.data(consts.DATA_FIELD_NAME);
      $allHeaders.removeClass('ordered-asc');
      $allHeaders.removeClass('ordered-desc');
      $this.addClass(sortType);

      if (config.endpoint) {
        clearRows($dataList);
        requestResultData($dataList, config.endpoint, getResultUrlFromDataListWithSort($dataList, fieldName, sortType), 0);
      } else {
        var data = [];
        $('.data-list-spot__row', $dataList).each(function () {
          var $this = $(this),
              selector = '.data-list-spot__row__column.' + fieldName + ' span';
          data.push({
            'row': $this,
            'text': $(selector, $this).text()
          });
        });
        data.sort(function (a, b) {
          if (sortType == 'ordered-asc') {
            return a.text.toLowerCase() < b.text.toLowerCase() ? -1 : 1;
          }

          return b.text.toLowerCase() < a.text.toLowerCase() ? -1 : 1;
        });
        clearRows($dataList);
        data.forEach(function (item, index, array) {
          $('.data-list-spot__table.items', $dataList).append(item.row);
        });
      }
    });
  },
      buildRow = function buildRow($dataList, dataRow, index) {
    var config = getConfigForDataList($dataList),
        columns = config ? config.fields : [],
        value,
        key,
        style,
        hideOnMobile,
        hideOnTablet,
        classes,
        $header,
        $row = $('<div class="data-list-spot__row data-list-spot__row__cell remove-on-search"></div>');
    DGS.DataListSpotConfig.decorateRowAtIndex(dataRow, index, $row), columns.forEach(function (column) {
      classes = ["data-list-spot__row__column"];
      key = column.fieldName;
      classes.push(key);
      style = column.className ? " " + column.className : '', classes.push(style);
      $header = $('.data-list-spot__headers__header.' + key, $dataList);
      hideOnMobile = $header.hasClass('hide-on-mobile');
      hideOnTablet = $header.hasClass('hide-on-tablet');
      value = dataRow.hasOwnProperty(key) && dataRow[key] ? dataRow[key] : '';

      if (value instanceof Array) {
        if (value.length > 0 && !(value[0] instanceof String)) {
          var tmpValue = [];
          value.forEach(function (arrEntry) {
            for (var arrKey in arrEntry) {
              tmpValue.push("<p class=\"".concat(arrKey, "\">").concat(arrEntry[arrKey], "</p>"));
            }
          });
          value = tmpValue.join('');
        } else {
          value = '<p>' + value.join('</p><p>') + '</p>';
        }
      }

      if (hideOnMobile) {
        classes.push('hide-on-mobile');
      }

      if (hideOnTablet) {
        classes.push('hide-on-tablet');
      }

      try {
        $row.append($('<div class="' + classes.join(' ') + '" data-value="' + value.replace(/"/g, '') + '"><span>' + value + '</span></div>'));
      } catch (e) {
        $row.append($('<div class="' + classes.join(' ') + '"><span>' + value + '</span></div>'));
      }
    });

    if ($dataList.hasClass('toggle-billto')) {
      // SHOULD BE MOVED TO DATASET PROVIDED BY BACKEND
      dataRow.links = [{
        href: $dataList.data('endpoint').replace('$accountNo', dataRow.accountNo),
        method: "ws-refresh",
        rel: "self"
      }];
    }

    if ($dataList.hasClass('toggle-sellto')) {
      // SHOULD BE MOVED TO DATASET PROVIDED BY BACKEND
      dataRow.links = [{
        href: "$billToEndpoint/" + dataRow.accountNo,
        method: "ws",
        rel: "self"
      }];
    }

    if (dataRow.links && dataRow.links.length > 0) {
      $row.addClass('clickable');
      $('.data-list-spot__row__column', $row).addClass('clickable');
      $('.data-list-spot__row__column', $row).click({
        row: dataRow
      }, function (ev) {
        if (!$(this).hasClass('clickable') || $(this).hasClass('checkbox') || $(this).hasClass('printable')) return;
        if (isCollapsed($dataList)) updateSelectedCollapsedValue($dataList, ev.data.row);

        if (!gotoDetailLink(ev.data.row)) {
          setFilterValue($dataList, ev.data.row);
        }
      });
    }

    return $row;
  },
      setDefaultValue = function setDefaultValue($dataList, data) {
    var items = getItems(data);
    items.forEach(function (row) {
      if (row.hasOwnProperty('_selected') && row._selected) {
        updateSelectedCollapsedValue($dataList, row);
      }
    });
  },
      gotoDetailLink = function gotoDetailLink(dataRow) {
    var detailsLink = dataRow.links[0].href,
        method = dataRow.links[0].method;

    if (detailsLink) {
      if (method.toLowerCase().substr(0, 2) == "ws") {
        var $dataListsAffected = $('.data-list-spot:not(.collapsed)');
        $dataListsAffected.each(function () {
          // Set all data lists on page to show loader while waiting for webservice
          var config = getConfigForDataList($(this));
          DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_LIST_PRE_SEARCH, config.endpoint);
        });

        if (detailsLink.indexOf('$billToEndpoint') !== -1) {
          // HARDCODED for toggle-sellto
          var $toggleBillTo = $('.toggle-billto'),
              toggleBillToConfig = $toggleBillTo.length ? getConfigForDataList($toggleBillTo) : null;

          if (toggleBillToConfig !== null) {
            toggleBillToConfig.data.items.forEach(function (item) {
              if (item._selected) {
                detailsLink = detailsLink.replace('$billToEndpoint', $toggleBillTo.data('endpoint')).replace('$accountNo', item.accountNo);
              }
            });
          }
        } // DO ajax and then refresh


        $.ajax(detailsLink, {
          complete: function complete() {
            if (method.toLowerCase() === "ws-refresh") {
              window.location.reload(true);
            } else {
              $dataListsAffected.each(function () {
                // refresh data for all data lists on page
                var config = getConfigForDataList($(this));
                DGS.Event.getInstance().trigger(DGS.DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY, config.endpoint, null, null, {}, 'or', '');
              });
            }
          }
        });
        return true;
      }

      window.location = detailsLink;
      return true;
    }

    return false;
  },
      updateSelectedCollapsedValue = function updateSelectedCollapsedValue($dataList, dataRow) {
    var labelFormat = $dataList.attr('data-label-format'),
        s;

    for (s in dataRow) {
      if (labelFormat.indexOf('$' + s) != -1) {
        labelFormat = labelFormat.replace('$' + s, dataRow[s]);
      }
    }

    $('.data-list-selected-value', $dataList).text(labelFormat);
    $('.component-content', $dataList).removeClass('open');
  },
      setFilterValue = function setFilterValue($dataList, dataRow) {
    var detailsFilterField, detailsFilterValue;

    if (dataRow.links && dataRow.links.length > 0) {
      detailsFilterField = dataRow.links[0].filterField;
      detailsFilterValue = dataRow.links[0].filterValue;

      if (detailsFilterField && detailsFilterValue) {
        DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_SEARCH_SET_GLOBAL_FILTER, detailsFilterField, detailsFilterValue);
        return true;
      }
    }

    return false;
  },
      buildRows = function buildRows($dataList, data, $container) {
    var rowCounter = $('.data-list-spot__row', $dataList).length,
        initialRowCounter = rowCounter,
        config = getConfigForDataList($dataList),
        maxNumRows = config.maxCount || -1,
        hasMoreRows = false,
        items = getItems(data);
    items.forEach(function (row) {
      if (maxNumRows == -1 || rowCounter < maxNumRows) {
        $container.append(buildRow($dataList, row, rowCounter++));
      } else {
        hasMoreRows = true;
      }
    });
    showRows($('.data-list-spot__row:nth-child(n+' + initialRowCounter + ')', $dataList));
    return !hasMoreRows;
  },
      getItems = function getItems(data) {
    if (data.hasOwnProperty("Items")) {
      data.items = data.Items;
    }

    if (data.hasOwnProperty("items")) {
      return data.items;
    }

    return [];
  },
      isCollapsed = function isCollapsed($dataList) {
    return $dataList.hasClass('collapsed');
  },
      isSearchable = function isSearchable($dataList) {
    return $dataList.hasClass('searchable');
  },
      clearRows = function clearRows($dataList) {
    $('.remove-on-search', $dataList).remove();
  },
      decorateCells = function decorateCells($dataList) {
    // ADD TEASER FUNCTIONALITY
    if (!isCollapsed($dataList)) {
      $(".data-list-spot__row__column", $dataList).off('mouseover');
      $(".data-list-spot__row__column", $dataList).on('mouseover', function () {
        var $this = $(this);
        var $element = $('span', $this);
        var $c = $element.clone().css({
          display: 'inline',
          width: 'auto',
          visibility: 'hidden'
        }).appendTo('body');

        if ($c.width() > $element.width()) {
          $this.addClass('show-full');
        }

        $c.remove();
      });
      $(".data-list-spot__row__column", $dataList).off('mouseout');
      $(".data-list-spot__row__column", $dataList).on('mouseout', function () {
        var $this = $(this);
        $this.removeClass('show-full');
      });
    }

    $(".data-list-spot__row__cell .data-list-spot__row__column.teaser", $dataList).hover(function () {
      $(this).addClass('show-full');
    }, function () {
      $(this).removeClass('show-full');
    }); // TRACKING CELLS

    $(".data-list-spot__row__cell .data-list-spot__row__column.tracking", $dataList).each(function () {
      var $this = $(this),
          text = $this.text();

      if (text.trim().substr(0, 4) == 'http') {
        $this.addClass('non-clickable');
        $this.removeClass('clickable');
        $this.html('<a href="' + text + '" target="_blank">&nbsp;</a>');
        $this.click(function (e) {
          var href = $('a', $(this)).attr('href'),
              target = $('a', $(this)).attr('target');

          if (target == '_blank') {
            window.open(href);
          } else {
            window.location = href;
          }
        });
      }
    }); // TRACKING CELLS
    // MULTI TRACKING CELLS

    $(".data-list-spot__row__cell .data-list-spot__row__column.packing-slips", $dataList).each(function () {
      var $this = $(this),
          $packingSlipNos = $('.packingSlipNo', $this),
          $trackingUrls = $('.trackingUrl', $this);
      $this.addClass('non-clickable');
      $this.removeClass('clickable');
      var packingSlipNos = [];
      $packingSlipNos.each(function () {
        packingSlipNos.push($(this).text());
      });
      $trackingUrls.each(function () {
        var $this = $(this),
            text = $this.text();
        $this.html('<a href="' + text + '" target="_blank">' + packingSlipNos.shift() + '</a>');
      });
    }); // TRACKING CELLS
  },
      addViewAllComponent = function addViewAllComponent($dataList, $container) {
    var config = getConfigForDataList($dataList),
        label = config.readMoreText || "View all",
        href = config.readMoreLink || "#";
    $('.data-list-spot__view-all-container', $dataList).remove();
    $container.append('<div class="data-list-spot__view-all-container remove-on-search"><div><a href="' + href + '" class="link data-list-spot__view-all">' + label + '</a></div></div>');
    setTimeout(function () {
      showContent($('.data-list-spot__view-all-container div', $container));
    }, 200);
  },
      addLoadMoreComponent = function addLoadMoreComponent(endpoint, $dataList, data, $container) {
    var config = getConfigForDataList($dataList),
        pagination = data.pagination,
        paginationLinks = pagination != null ? pagination.links : [],
        nextLink,
        link,
        i,
        countPaginationLinks = paginationLinks.length,
        loadMoreLabel = config.loadMoreText || "Load more",
        loadMoreLink,
        sortObj,
        orderBy;
    $('.data-list-spot__load-more-container', $dataList).remove();

    for (i = 0; i < countPaginationLinks; i++) {
      link = paginationLinks[i];

      if (link.rel == "next") {
        nextLink = link;
      }
    }

    if (nextLink != null && config['maxCount'] == "") {
      loadMoreLink = nextLink.href;
      sortObj = getSortFromDataList($dataList);

      if (sortObj.fieldName && sortObj.sortType) {
        orderBy = DataListSpotConfig.resolveSortForField(sortObj.fieldName, sortObj.sortType);
      }

      if (!orderBy && config['sortOrder']) {
        orderBy = 'orderby=' + config['sortOrder'];
      }

      if (orderBy) loadMoreLink += loadMoreLink.indexOf('?') !== -1 ? '&' + orderBy : '?' + orderBy;
      $container.append('<div class="data-list-spot__load-more-container remove-on-search"><div class="data-list-spot__load-more"><a class="link" href="' + loadMoreLink + '">' + loadMoreLabel + '</a></div></div>');
      showContent($('.data-list-spot__load-more-container', $container));
      $('.data-list-spot__load-more-container a', $container).click(function (event) {
        event.preventDefault();
        buildLoader($dataList);
        requestResultData($dataList, endpoint, $(this).attr('href'), false, 0);
      });
    }
  },
      buildLoader = function buildLoader($dataList) {
    $('.data-list-spot__load-more-container', $dataList).remove();
    $('.data-list-spot__load-container', $dataList).remove();
    $(".component-content", $dataList).append('<div class="data-list-spot__load-container"></div>');
    $('.data-list-spot__load-container', $dataList).append('<div class="cssload-container"><div class="cssload-double-torus"><div class="cssload-double-torus-arrow"></div></div></div>');
    showContent($('.data-list-spot__load-container', $dataList));
  },
      removeLoader = function removeLoader($dataList) {
    $('.data-list-spot__load-container', $dataList).remove();
  },
      addEmptyResultComponent = function addEmptyResultComponent($dataList, $container) {
    var config = getConfigForDataList($dataList),
        label = config['emptyResultText'] || "No records match your search.";
    $('.data-list-spot__empty_result-container', $dataList).remove();
    $container.append('<div class="data-list-spot__empty_result-container remove-on-search"><div class="data-list-spot__empty_result">' + label + '</div></div>');
    showContent($('.data-list-spot__empty_result-container', $container));
  },
      getConfiguredDateRange = function getConfiguredDateRange($dataList) {
    var toDate = new Date(),
        fromDate = new Date(),
        config = getConfigForDataList($dataList),
        dateRange = config['dateRange'] ? config['dateRange'] : "60d";

    if (dateRange.indexOf('m') > -1) {
      fromDate.setMonth(fromDate.getMonth() - parseInt(dateRange));
    } else if (dateRange.indexOf('y') > -1) {
      fromDate.setYear(fromDate.getYear() - parseInt(dateRange));
    } else {
      fromDate.setDate(fromDate.getDate() - parseInt(dateRange));
    }

    return {
      from: fromDate,
      to: toDate
    };
  },
      getDataForConfiguredDateRange = function getDataForConfiguredDateRange($dataList, endpoint) {
    DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_SEARCH_QUERY_READY, endpoint, null, null, {}, 'and', null);
  },
      saveResultUrlOnDataList = function saveResultUrlOnDataList($dataList, responseUrl) {
    $dataList.data(consts.DATA_RESPONSE_URL, responseUrl);
  },
      getResultUrlFromDataListWithSort = function getResultUrlFromDataListWithSort($dataList, fieldName, sortType) {
    saveSortOnDataList($dataList, fieldName, sortType);
    var responseUrl = $dataList.data(consts.DATA_RESPONSE_URL),
        orderBy;

    if (responseUrl !== null) {
      orderBy = DataListSpotConfig.resolveSortForField(fieldName, sortType);
      return responseUrl.indexOf('?') !== -1 ? responseUrl + '&' + orderBy : responseUrl + '?' + orderBy;
    }
  },
      getSortFromDataList = function getSortFromDataList($dataList) {
    return {
      fieldName: $dataList.data(consts.DATA_FIELD_NAME),
      sortType: $dataList.data(consts.DATA_SORT_ORDER)
    };
  },
      saveSortOnDataList = function saveSortOnDataList($dataList, fieldName, sortType) {
    $dataList.data(consts.DATA_FIELD_NAME, fieldName);
    $dataList.data(consts.DATA_SORT_ORDER, sortType);
  },
      clearSort = function clearSort($dataList) {
    var sortObj = getSortFromDataList($dataList);

    if (sortObj.fieldName && sortObj.sortType) {
      $('.data-list-spot__headers__header.' + sortObj.fieldName, $dataList).removeClass(sortObj.sortType);
    }

    $dataList.removeData(consts.DATA_FIELD_NAME);
    $dataList.removeData(consts.DATA_SORT_ORDER);
  };

  DGS.OnLoad.getInstance().register(initDataListSpot);
  DGS.Event.getInstance().on('re-init-data-list-spot', initDataListSpot);
  DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_BUILD_QUERY, function ($dataListSearchSpot, endpoint) {
    DataListSpotConfig.initialRequests[endpoint] = {
      queryType: DataListSpotConfig.Event.DATA_REQUEST_BUILD_QUERY,
      $searchSpot: $dataListSearchSpot
    };
    DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_RECEIVED, endpoint);
  });
  DGS.Event.getInstance().on(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_BUILD_ADV_QUERY, function ($dataListSearchSpot, endpoint) {
    DataListSpotConfig.initialRequests[endpoint] = {
      queryType: DataListSpotConfig.Event.DATA_REQUEST_BUILD_ADV_QUERY,
      $searchSpot: $dataListSearchSpot
    };
    DGS.Event.getInstance().trigger(DataListSpotConfig.Event.DATA_REQUEST_INITIAL_RECEIVED, endpoint);
  });
  DGS.DataListSpotConfig = DataListSpotConfig;
})(jQuery, window.DGS);