import WDHWebFormForMarketers from "../../../../wdh-feature/wdh-wffm";

(function (DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var wffm = new WDHWebFormForMarketers(document.querySelectorAll('.component.form'), {
      moveAsteriskToLabel: true,
      maxEntriesInValidatorSummary: 5,
      usefulInfoAsPlaceholder: true
    });
    wffm.init();
    var forms = $('.component.form:not(.scEnabledChrome)');
    forms.each(function (i, form) {
      var labels = $('label', $(form));
      labels.each(function (j, label) {
        label.innerHTML = label.innerHTML.replace('[', '<sup class="footnote-reference">').replace(']', '</sup>');
      });
      var useful = $('.scfCheckBoxListUsefulInfo', $(form));
      useful.each(function (j, usefulInfoBox) {
        usefulInfoBox.innerHTML = usefulInfoBox.innerHTML.replace('[', '<sup class="footnote-reference">').replace(']', '</sup>');
      }); // Move helper texts (if any) below their corresponding input fields

      $('.helper-text', $(form)).each(function () {
        $(this).removeClass('helper-text');
        var targetId = $(this).attr('class');
        $(this).addClass('helper-text');
        $(this).insertAfter($('#' + targetId));
      });
    });
  });
})(window.DGS);