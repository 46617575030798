import { ComponentBootstrap } from "@demant/wdh-react-bootstrap";
import PlainImageSpot from "./components/plain-image-spot";
import AccordionSpot from "./components/accordion-spot";
import FrontpageSectionMenu from "./components/frontpage-section-spot";
import AsTabSpot from "./components/as-tab";
import { Component } from "react"; // PLAIN, ROUND, DIVIDERS, BORDERS

var plainImageSpot = new ComponentBootstrap("image-spot", ":not(.accordion):not(.as-sectionmenu)");
plainImageSpot.reactApp = PlainImageSpot;
plainImageSpot.run(); // ACCORDION

var accordionSpot = new ComponentBootstrap("image-spot", ".accordion");
accordionSpot.reactApp = AccordionSpot;
accordionSpot.run(); // FRONTPAGE SECTION MENU

var frontpageSectionSpot = new ComponentBootstrap("image-spot", ".as-sectionmenu");
frontpageSectionSpot.reactApp = FrontpageSectionMenu;
frontpageSectionSpot.run(); // AS TAB

var asTabSpot = new ComponentBootstrap("image-spot", ".as-tab");
asTabSpot.reactApp = AsTabSpot;
asTabSpot.run();