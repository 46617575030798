(function ($, DGS) {
  var instance;
  DGS.Components = window.DGS.Components || {};

  DGS.Components.DataHierarchySpot = window.DGS.Components.DataHierarchySpot || function () {
    var init = function init() {
      var registerVariation = function registerVariation(className, configHandler, onBuildHandler) {
        $(".component.data-hierarchy-spot." + className).each(function () {
          var $that = $(this);
          DGS.Components.DataHierarchySpot.getInstance().loadData($that, function (data) {
            try {
              var builderConfig,
                  parsedData = parseData(data);

              if (configHandler) {
                builderConfig = configHandler.apply(null, [parsedData, $that]);

                if (builderConfig) {
                  if (!builderConfig.mainContainer) {
                    builderConfig.mainContainer = $('.component-content', $that);
                  }

                  window.DGS.Builder.getInstance().build(builderConfig);
                  if (onBuildHandler) onBuildHandler.apply(null, [$that, parsedData]);
                }
              }
            } catch (error) {
              console.error(error);
            }
          });
        });
      },
          loadData = function loadData($element, callback) {
        var data = $('script[type="application/json"]', $element);

        try {
          callback(JSON.parse(data.html()));
        } catch (e) {
          console.error(e);
        }
      },
          loadTemplate = function loadTemplate($element, path, callback) {
        $.ajax({
          url: path,
          dataType: "html"
        }).then(function (data) {
          $element.find('.component-content').html(data);
          callback.apply();
        }, function (jqXHR, textStatus, msg) {
          callback.apply();
        });
      },
          parseData = function parseData(data) {
        return parseChild(data);
      },
          parseChild = function parseChild(child) {
        var output = {};

        for (var s in child) {
          if (s != 'children' && s != 'itemInfo') {
            output[s] = child[s];
          }
        }

        if (!output.hasOwnProperty('id')) {
          output.id = child.itemInfo.name;
        }

        if (child.hasOwnProperty('children')) {
          parseChildren(child, output);
        }

        return output;
      },
          parseChildren = function parseChildren(data, output) {
        data.children.forEach(function (child) {
          var name = child.hasOwnProperty('_property') ? child._property : child.itemInfo.template;

          if (!output.hasOwnProperty(name)) {
            output[name] = [];
          }

          output[name].push(parseChild(child));
        });
      };

      return {
        register: registerVariation,
        loadTemplate: loadTemplate,
        loadData: loadData,
        parseData: parseData
      };
    };

    return {
      /**
       * Returns the current instance
       * @memberof DGS.Components.DateHierarchySpot
       */
      getInstance: function getInstance() {
        if (!instance) {
          instance = init();
        }

        return instance;
      }
    };
  }();

  DGS.OnLoad.getInstance().register(function () {
    DGS.WaitForLoad.getInstance().register($('.component.loader'), false);
    setTimeout(function () {
      $('.component.loader').addClass('remove');
      DGS.LoadComplete.getInstance().register($('.component.loader'));
    }, 1000);
  });
})(jQuery, window.DGS);