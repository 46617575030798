/* eslint-disable func-names */
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var button = $('.floating-action-button');

    if (button.length) {
      $(window).scroll(function () {
        if (window.matchMedia('only screen and (min-width: 1200px)').matches) {
          button.removeClass('footer-sticky');
          button.addClass('content-sticky');

          if (button.offset().top + button.outerHeight() > $('#footer').offset().top) {
            button.css('top', "".concat($('#footer').offset().top - button.outerHeight() - $('.header-desktop-top.outer-container').outerHeight(), "px"));
            button.addClass('footer-sticky');
            button.removeClass('content-sticky');
          }
        }
      });
    }
  });
})(jQuery);