/**
 * Created by mady on 18-04-2017.
 */
(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var getQueryParameters = function getQueryParameters(str) {
      return (str || document.location.search).replace(/(^\?)/, '').split("&").map(function (n) {
        return n = n.split("="), this[n[0]] = n[1], this;
      }.bind({}))[0];
    },
        decorateCookieFromURL = function decorateCookieFromURL(cookie) {
      var queryParams = getQueryParameters();

      for (var id in cookie) {
        var obj = typeof cookie[id] === "string" ? JSON.parse(cookie[id]) : cookie[id];

        for (var key in obj) {
          var value = obj[key];

          if (typeof value === "string") {
            for (var param in queryParams) {
              obj[key] = value.replace("{" + param + "}", queryParams[param]);
            }
          }
        }

        cookie[id] = JSON.stringify(obj);
      }

      return cookie;
    },
        writeDataAttributes = function writeDataAttributes($spot, object) {
      for (var id in object) {
        var obj = typeof object[id] === "string" ? JSON.parse(object[id]) : object[id];

        for (var key in obj) {
          $spot.attr('data-' + key, obj[key]);
        }
      }
    },
        createCookie = function createCookie(name, value, days) {
      var expires;

      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
      } else {
        expires = "";
      }

      document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    },
        readCookie = function readCookie(name) {
      var nameEQ = encodeURIComponent(name) + "=";
      var ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }

      return null;
    },
        buildConfigHandler = function buildConfigHandler(data, $spot) {
      /* SET BASE PATH COOKIE VAR */
      var cookie = readCookie("appframe-component"),
          cookieObject = cookie ? JSON.parse(cookie) : {};
      cookieObject[data.cookieProjectName] = data.cookieBasePath || "";
      cookieObject = decorateCookieFromURL(cookieObject);
      createCookie("appframe-component", JSON.stringify(cookieObject));
      writeDataAttributes($spot, cookieObject);
      /* SET BASE PATH COOKIE VAR */

      var resources = [];

      if ($spot.hasClass('iframe')) {
        resources.push($('<iframe src="https://cdn.dgs.com/tool/product-tool/" width="1024" height="704"></iframe>'));
      } else {
        if (data.hasOwnProperty('css')) {
          data.css.forEach(function (entry) {
            resources.push($('<link rel="stylesheet" type="text/css" href="' + entry.url.url + '" />'));
          });
        }

        if (data.hasOwnProperty('js')) {
          data.js.forEach(function (entry) {
            resources.push($('<script src="' + entry.url.url + '" type="text/javascript"></script>'));
          });
        }
      }

      return {
        mainContainer: $('.component-content', $spot),
        sections: {
          desktop: {
            containers: [{
              id: data.containerCSSSelector.indexOf('#') == 0 ? data.containerCSSSelector.substr(1) : null,
              className: data.containerCSSSelector.indexOf('.') == 0 ? data.containerCSSSelector.substr(1) : null
            }, {
              className: "resources",
              components: resources
            }]
          }
        }
      };
    };

    DGS.Components.DataHierarchySpot.getInstance().register('appframe-component', buildConfigHandler, function ($spot, data) {
      DGS.Resizer.getInstance().register(function () {
        // PRODUCT TOOL SPECIFIC AUTO SCALING FIX, BECAUSE PRODUCT TOOL IS NOT RESPONSIVE ITSELF
        $('.component.data-hierarchy-spot.appframe-component.autoscale').each(function () {
          var componentContentWidth = $('.component-content', $(this)).innerWidth(),
              scaleFactor = componentContentWidth / 1024;
          $('.desktop-section > .inner-container').css('transform', 'scale(' + scaleFactor + ')');
          $('.desktop-section > .inner-container').outerHeight(710 * scaleFactor);
        }); // PRODUCT TOOL SPECIFIC AUTO SCALING FIX, BECAUSE PRODUCT TOOL IS NOT RESPONSIVE ITSELF - end
      });
    });
  });
})(jQuery, window.DGS);