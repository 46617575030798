function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function ($) {
  window.DGS.CenterSpotConfig = {
    mapBrowserWidth: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    var $thisSpot = $('.centre-spot');

    if ($thisSpot.length) {
      var $breadcrumb = $('.breadcrumb');

      if ($breadcrumb.length) {
        var centerName = $('#center-name').text();
        var centerRegionName = $('#region-item-name').text(),
            centerRegion = $('#region-name').text();
        var links = $breadcrumb.find('a[title="*"]'); // Changing region wildcards to actual region name

        $(links[0]).text(centerRegion);
        $(links[0]).prop('title', centerRegion);
        var centerRegionLink = $(links[0]).prop('href');

        if (typeof centerRegionLink !== 'undefined') {
          centerRegionLink = centerRegionLink.replace(',-w-,', centerRegionName);
          $(links[0]).prop('href', centerRegionLink.replace(/\/$/, ''));
        } // Changing name wildcards to actual center name


        $(links[1]).text(centerName);
        $(links[1]).prop('title', centerName);
        var centerNameLink = $(links[1]).prop('href');

        if (typeof centerNameLink !== 'undefined') {
          centerNameLink = centerNameLink.replace(',-w-,', centerName);
          $(links[1]).prop('href', centerNameLink.replace(/\/$/, ''));
        }
      }

      $thisSpot.each(function () {
        var host = window.location.host; // document.title = document.getElementById('centre-map').getAttribute('data-name');

        function initMap() {
          if (window.DGS.CenterSpotConfig.mapBrowserWidth) $('#centre-map').width($('body').width());
          var latLangArray;
          var myLatLng;
          var latLang = $('.centre-cta.button', $thisSpot).attr('id');

          if (latLang.length > 0) {
            latLangArray = latLang.split(',');
            myLatLng = {
              lat: parseFloat(latLangArray[0]),
              lng: parseFloat(latLangArray[1])
            };
          } else {
            myLatLng = {
              lat: 0,
              lng: 0
            };
          }

          var map = new google.maps.Map(document.getElementById('centre-map'), {
            zoom: parseInt(document.getElementById('centre-map').getAttribute('data-zoom')),
            center: myLatLng,
            disableDefaultUI: true,
            zoomControl: false,
            scaleControl: false,
            draggable: false,
            scrollwheel: false,
            disableDoubleClickZoom: true
          });
          $thisSpot.data('configuration');
          var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: document.getElementById('centre-map').getAttribute('data-icon')
          });
        }

        $('.centre-cta.button', $thisSpot).on('click', function (e) {
          e.preventDefault();
          window.open("https://maps.google.com?daddr=".concat($(this).attr('id')), '_blank', 'toolbar=no, scrollbars=no, resizable=yes, top=0, left=0, width=900,height=650');
        });
        window.DGS.Resizer.getInstance().register(function () {
          if ((typeof google === "undefined" ? "undefined" : _typeof(google)) === 'object' && _typeof(google.maps) === 'object') {
            initMap();
          }
        });
      });
    }
  });
})(jQuery);