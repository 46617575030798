(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var cookieName = "lang-check";
    DGS.Event.getInstance().on(window.DGS.Events.BUILDER_DONE, function (settings) {
      var $languageSpot = $('#header .component.language-selector-spot');
      var isHeader = settings.mainContainer.length && settings.mainContainer[0].className.indexOf("header") != -1;
      if (!isHeader) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $languageSpot.each(function () {
        var languageSpot = $(this);
        var $ul = $('.component-content ul', languageSpot),
            liCount = $('li', $ul).length,
            $slider = $('.slider', $ul),
            $languagePopup = $("#header .component.language-selector-spot .popup--choose-language"),
            urlLength = 0,
            sliderWidth,
            tempThis,
            tempURL = '',
            theURL;

        if (liCount > 0) {
          if ($slider.length === 0) {
            $slider = $('<div class="slider"></div>');
            $ul.prepend($slider);
          }

          sliderWidth = 100 / liCount;
          $slider.css({
            'width': sliderWidth + '%'
          });
          theURL = document.location.href.replace('http://', '').replace('https://', '');
          $('li', $ul).each(function (index) {
            tempURL = $('a', $(this)).attr('href').replace('http://', '').replace('https://', '');

            if (theURL.indexOf(tempURL) == 0 && tempURL.length > urlLength) {
              // || (theURL.indexOf("localhost:3000") === 0 && index === 0)){
              urlLength = tempURL.length;
              if (tempThis !== undefined) tempThis.removeClass('active');
              tempThis = $(this);
              $(this).addClass('active');
              $slider.addClass('active');
              calculatePosition($(this), liCount, $slider, sliderWidth);
            }

            $(this).click(function (e) {
              e.preventDefault();
              var goTo = $(this).find("a").attr("href");
              $('li', $ul).removeClass('active');
              $(this).addClass('active');
              $slider.addClass('active');
              calculatePosition($(this), liCount, $slider, sliderWidth);
              setTimeout(function () {
                window.location = goTo;
              }, 500);
            });
          });
        } else {
          languageSpot.css('display', 'none');
        }

        if ($languagePopup.length) {
          var language_cookie_check = window.DGS.Cookies.getInstance().readCookie(cookieName);
          initOverlay($languagePopup);

          if (language_cookie_check === null) {
            showOverlay();
          }
        }
      });
    });

    function calculatePosition(element, tLength, $slider, sliderWidth) {
      var newPos = (element.index() - 1) * sliderWidth;

      if (newPos <= 0) {
        newPos = 3;
      } else {
        newPos += 1;
        if (tLength == element.index()) newPos -= 3;
      }

      $slider.css('left', newPos + '%');
    }

    function initOverlay($languagePopup) {
      $('#wrapper').append('<div class="popup--choose-language-overlay"><div class="popup-overlay-content"></div></div>');
      var $overlay = $('.popup--choose-language-overlay'),
          $content = $('.popup-overlay-content', $overlay);
      $($languagePopup).appendTo($content);
      var $links = $(".popup__link", $content);
      $links.click(function (e) {
        e.preventDefault();
        window.DGS.Cookies.getInstance().createCookie(cookieName, $(this).attr("href"), 1000000); //set to a long, long way in the future

        window.location = $(this).attr("href");
      });
    }

    function showOverlay() {
      var $overlay = $('.popup--choose-language-overlay');
      $overlay.fadeIn("fast");
    }
  });
})(jQuery);