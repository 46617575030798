require('core-js');require('regenerator-runtime/runtime');/* eslint-disable quotes */
Array.prototype.flat;

if (Array.prototype.flatten === undefined && Array.prototype.flat !== undefined) {
  Array.prototype.flatten = Array.prototype.flat;
} // FOUNDATION


import $ from "jquery";
import { TweenMax } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollMagic from "scrollmagic";
window.jQuery = $;
window.$ = $;

require("../../../wdh-foundation/dateformat/dateformat.js");

require("../../../wdh-foundation/dgs/dgs-api.js");

require("../../../wdh-foundation/dgs/dgs-events.js"); // require('@demant/megamenu');


require("../../../wdh-foundation/dgs-utils/dgs-utils.js");

require("../../../wdh-foundation/dgs-sharing/dgs-sharing.js"); // require('@demant/burgermenu');


require('@demant/legacy/googlemaps');

require("iframe-resizer");

require("jquery-nice-select/js/jquery.nice-select.min.js");

require("jquery-ui-dist/jquery-ui.min.js");

require("lightgallery/src/js/lightgallery.js");

require("@demant/legacy/lunametrics");

require("jquery-bridget");

require("masonry-layout");

require("moment");

require("scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap"); // FEATURE


require("./feature/component-banner-spot.js");

require("./feature/component-center-list-spot.js");

require("./feature/component-center-spot.js");

require("./feature/component-country-selector.js");

require("./feature/component-a-data-hierarchy.js");

require("./feature/component-b-data-hierarchy-appframe-component.js");

require("./feature/component-b-data-hierarchy-hubspot.js");

require("./feature/component-b-data-hierarchy-multi-step.js"); //require("./feature/component-b-data-hierarchy-resource-center.js");


require("./feature/component-data-hierarchy-dataset.js");

require("./feature/component-data-list-search-spot.js");

require("./feature/component-data-list-spot.js");

require("./feature/component-distributor-locator.js"); //require("./feature/component-download-center.js");


require("./feature/jquery-cycle.js");

require("./feature/component-iframe.js");

require("./feature/component-intro-banner.js");

require("./feature/component-job-list-spot.js");

require("./feature/component-lattice-spot.js");

require("./feature/component-leaving-notification.js");

require("./feature/component-link-list.js");

require("./feature/component-locations-spot/component-locations-spot.js");

require("./feature/component-option-spot.js");

require("./feature/component-resource-center/component-resource-center.js");

require("./feature/product-tool/colour-selector.js");

require("./feature/product-tool/hammer.js");

require("./feature/product-tool/jqueryhammer-full.js");

require("./feature/product-tool/knockout-310.js");

require("./feature/product-tool/model-selector.js");

require("./feature/component-richtext-submenu.js");

require("./feature/component-spot-control-spot.js");

require("./feature/component-submenu.js");

require("./feature/component-tile.js");

require("./feature/component-video-spot.js");

require("./feature/component-alternative-url.js");

require("./feature/component-box-spot.js");

require("./feature/component-color-selector-spot.js");

require("./feature/component-contact-information.js"); // require('./feature/component-cookies.js');


require("./feature/component-form");

require("./feature/component-hearing-center-locator.js");

require("./feature/component-iframe-us.js"); // require('./feature/custom-component-image-spot.js'); // old image-spot


require("./feature/component-image-spot/index.js"); // new image-spot


require("./feature/component-job-detail-spot.js");

require("./feature/language-selector-spot.js");

require("./feature/component-news-list-spot.js");

require("./feature/component-pairing-guide.js");

require("./feature/component-producttool.js");

require("./feature/component-profile-spot.js");

require("./feature/component-screening-test.js");

require("./feature/component-search.js");

require("./feature/component-tagged-list-spot.js");

require("./feature/component-testimonial-spot.js");

require("./feature/component-text-image-spot.js");

require("./feature/component-two-section-grid.js");

require("./feature/landing-page-plugins.js");

require("./feature/component-blog/blog-categories/component-blog-categories");

require("./feature/component-blog/blog-list/component-blog-list");

require("./feature/component-blog/blog-post/component-blog-post");

require("./feature/component-blog/blog-author/component-blog-author");

require("./feature/component-blog/blog-related/component-blog-related");

require("./feature/component-blog/blog-author-details/component-blog-author-details");

require("./feature/component-blog/author-related-blogs/component-author-related-blogs");

require("./feature/component-section-menu");

require("./feature/cards/component-flex-spot-cards");

require("./feature/comparison/flex-spot-comparison");

require("./feature/component-geo-ip-prompt.js"); // PROJECT


require("./project/base-main-custom.js");

require("./project/tracking-gtm.js");

require("imports-loader?ScrollMagic=ScrollMagic!./feature/landing-page.js"); // eslint-disable-line


require("./project/custom-contact-float.js");

require("./project/custom-datepicker.js");

require("./project/custom-flowtype.js");

require("./project/custom-footer.js");

require("./project/custom-header/index.js");

require("./project/custom-newslist.js");

require("./project/custom-sharing.js");

require("./project/custom-to-top.js");

require("./project/flowtype.js");

require("./project/main.js");

require('@demant/wdh-gdpr/lib/default.implementation.js');

require("./2021/index");