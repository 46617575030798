/**
 * @version 1.0
 * @namespace DGS
 */
var DGS = window.DGS || {};
/**
 * @version 1.0
 * @namespace DGS.UserAgent
 * MOVED TO @demant/wdh isMobileDevice
 */

DGS.UserAgent = function ($) {
  var instance;

  function init() {
    function mobileTabletCheck() {
      return navigator.userAgent.match(/Android|webOS|iPhone|iPod|iPad|Blackberry|IEMobile|Opera Mini/i);
    }

    return {
      mobileTabletCheck: mobileTabletCheck
    };
  }

  return {
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * @version 1.0
 * @namespace DGS.Cookies
 * Moved to @demant/wdh createCooke, readCookie
 */


DGS.Cookies = function ($) {
  var instance;

  function init() {
    function createCookie(name, value, days) {
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        var expires = "; expires=".concat(date.toGMTString());
      } else {
        expires = '';
      }

      document.cookie = "".concat(name, "=").concat(value).concat(expires, "; path=/");
    }

    function readCookie(name) {
      var nameEQ = "".concat(name, "=");
      var ca = document.cookie.split(';');

      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];

        while (c.charAt(0) == ' ') {
          c = c.substring(1, c.length);
        }

        if (c.indexOf(nameEQ) == 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }

      return null;
    }

    return {
      createCookie: createCookie,
      readCookie: readCookie
    };
  }

  return {
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * @version 1.0
 * @namespace DGS.Builder
 * Moved to @demant/wdh WDHBuilder
 */


DGS.Builder = function ($) {
  var instance;

  function init() {
    var $mainContainer, determineSectionCallback, defaultContainerName, defaultContainerNameIndex, sections, currentSection;
    defaultContainerName = 'header-container-';
    defaultContainerNameIndex = 0;
    sections = {};

    var createDefaultContainerName = function createDefaultContainerName() {
      return defaultContainerName + ++defaultContainerNameIndex;
    },
        buildSection = function buildSection(label, section) {
      sections[label] = [];
      var $mainSectionContainer = createContainer("".concat(label, "-section"));
      $mainSectionContainer.hide();
      addToContainer($mainSectionContainer, $mainContainer);
      sections[label].push($mainSectionContainer);

      if (section.containers) {
        section.containers.forEach(function (containerData) {
          var $container, $subContainer;

          if (!containerData.className && !containerData.id) {
            containerData.className = createDefaultContainerName();
          }

          $container = createContainer(containerData.className, containerData.id);

          if (containerData.containers) {
            containerData.containers.forEach(function (subContainerData) {
              if (!subContainerData.className) {
                subContainerData.className = createDefaultContainerName();
              }

              $subContainer = createContainer(subContainerData.className, containerData.id);
              addToContainer($subContainer, $('> .inner-container', $container));

              if (subContainerData.components) {
                subContainerData.components.forEach(function ($component) {
                  addToContainer($component.clone(true), $('> .inner-container', $subContainer));
                });
              }
            });
          }

          if (containerData.components) {
            containerData.components.forEach(function ($component) {
              addToContainer($component.clone(true), $('> .inner-container', $container));
            });
          }

          addToContainer($container, $('> .inner-container', $mainSectionContainer));
        });
      }
    },
        createContainer = function createContainer(className, id) {
      return $("<div id=\"".concat(id || '', "\" class=\"").concat(className || '', " outer-container\"><div class=\"inner-container\"></div></div>"));
    },
        addToContainer = function addToContainer($element, $container) {
      $container.append($element);
    },
        clearMainContainer = function clearMainContainer() {
      // $mainContainer.empty();
      $mainContainer.children().hide();
    },
        hasSection = function hasSection(section) {
      return sections[section];
    },
        displaySection = function displaySection(section) {
      if (section != currentSection) {
        currentSection = section;

        if (sections[section]) {
          clearMainContainer();
          sections[section].forEach(function ($container) {
            // addToContainer($container, $mainContainer);
            $container.show();
          });
          $('.component', $mainContainer).addClass('component-ready animate');
        }
      }
    },
        determineSuitableSection = function determineSuitableSection() {
      var section = DGS.getLayoutType(),
          implementationSpecificOverrideSection;

      if (!hasSection(section)) {
        section = DGS.DESKTOP_LAYOUT;
      }

      if (determineSectionCallback) {
        implementationSpecificOverrideSection = determineSectionCallback.apply(null, [section]);

        if (implementationSpecificOverrideSection) {
          return implementationSpecificOverrideSection;
        }
      }

      return section;
    },
        buildHeaderFromSettings = function buildHeaderFromSettings(settings) {
      // save main container
      if (!settings.mainContainer) throw "Can't build component. No 'mainContainer' defined.";
      $mainContainer = settings.mainContainer;
      determineSectionCallback = settings.determineSectionCallback; // build sections

      if (settings.sections) {
        $.each(settings.sections, buildSection);
      } // display desktop


      displaySection(determineSuitableSection());
      setTimeout(function () {
        DGS.Event.getInstance().trigger(DGS.Events.BUILDER_DONE, settings);
      }, 0); // setup resizer handler

      DGS.Resizer.getInstance().register(function () {
        displaySection(determineSuitableSection());
      });
    };

    return {
      build: buildHeaderFromSettings
    };
  }

  return {
    /**
         * Exposes the build method for the builder
         */
    build: function build(settings) {
      return init().build(settings);
    },

    /**
         * Returns the a new instance
         * @deprecated: This does not follow a singletone pattern, no "shared instance" involved. Use the build directly
         * @memberof DGS.Builder
         */
    getInstance: function getInstance() {
      return init();
    }
  };
}(jQuery);
/**
 * @version 1.0
 * @namespace DGS.Resizer
 * Moved to @demant/wdh onResize
 */


DGS.Resizer = function ($) {
  var instance,
      callbacks,
      timeoutInterval = -1;

  function init() {
    callbacks = [];
    /**
         * @memberof DGS.Resizer
         * @method register
         * @instance
         * @param {ResizerRegisterCallback} callback - The callback that handles the resize.
         */

    var registerResizeCallback = function registerResizeCallback(callback) {
      callbacks.push(callback);
      callback();
    };
    /**
         * @memberof DGS.Resizer
         * @method execute
         * @instance
         */


    function execute() {
      var callback, i, len;

      for (i = 0, len = callbacks.length; i < len; i += 1) {
        callback = callbacks[i];
        callback();
      }

      timeoutInterval = -1;
      setTimeout(function () {
        DGS.Event.getInstance().trigger(DGS.Events.RESIZE_DONE);
      });
    }

    $(window).on('resize', function () {
      if (timeoutInterval > -1) {
        clearTimeout(timeoutInterval);
      }

      timeoutInterval = setTimeout(function () {
        execute();
      }, 300);
    });
    $(window).on('load', function () {
      setTimeout(function () {
        execute();
      }, 0);
    });
    return {
      register: registerResizeCallback,
      execute: execute
    };
  }

  return {
    /**
           * @memberof DGS.Resizer
        */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
  * @callback ResizerRegisterCallback
 */

/* Resizer class */

/* Timer class */

/**
  * @version 1.0
 * @namespace DGS.Timer
 * NOT USED
 */


DGS.Timer = function ($) {
  var instance, callbacks, isRunning, interval;

  function init() {
    callbacks = {};
    /**
         * @memberof DGS.Timer
         * @instance
         * @method execute
         * @param {String} callbackId - A string that uniquely identifies the callback
         * @param {TimerExecuteCallback} callback - The callback that handles the resize.
         */

    var registerForExecute = function registerForExecute(callbackId, callback) {
      callbacks[callbackId] = callback;

      if (!isRunning) {
        // start timer
        interval = setInterval(executeCallbacks, 40);
        isRunning = true;
      }
    };

    function executeCallbacks() {
      var callback,
          i,
          len,
          key,
          count = 0;

      for (var key in callbacks) {
        count++;
        callback = callbacks[key];
        var isDone = callback();

        if (isDone) {
          delete callbacks[key];
        }
      }

      if (count == 0) {
        clearInterval(interval);
        isRunning = false;
      }
    }

    return {
      execute: registerForExecute
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.Timer
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * @callback TimerExecuteCallback
 * @returns {Boolean} false if execution is done and callback should be removed, true if timer execution should continue
 */

/* Timer class */

/* Event class */

/**
 * @version 1.0
 * @namespace DGS.Event
 * Moved to @demant/wdh onEvent, clearEvent, triggerEvent
 */


DGS.Event = function ($) {
  var instance, callbacks;

  function init() {
    window.WDHEventCallbacks = window.WDHEventCallbacks || {};
    callbacks = window.WDHEventCallbacks;
    /**
         * @memberof DGS.Event
         * @instance
         * @method on
         * @param {String} action - The name of the action to listen for.
         * @param {EventCallback} callback - The callback that executes when the action is triggered.
         */

    var on = function on(action, callback) {
      if (!(action in callbacks)) {
        callbacks[action] = [];
      }

      callbacks[action].push(callback);
    },

    /**
       * @memberof DGS.Event
       * @instance
       * @method off
       * @param {String} action - The name of the action to stop listening for.
       */
    off = function off(action, callback) {
      if (action in callbacks) {
        if (callback != undefined && callback != '') {
          callbacks[action].splice(callbacks[action].indexOf(callback), 1);
        } else {
          delete callbacks[action];
        }
      }
    },

    /**
       * @memberof DGS.Event
       * @instance
       * @method trigger
       * @param {String} action - The name of the action to trigger.
       */
    trigger = function trigger(action) {
      if (action in callbacks) {
        var handler,
            i,
            len,
            handlers = callbacks[action];
        [].shift.apply(arguments);

        for (i = 0, len = handlers.length; i < len; i += 1) {
          handler = handlers[i];
          handler.apply(void 0, arguments);
        }
      }

      var event = document.createEvent('Event');
      event.initEvent(action, true, true);
      document.body.dispatchEvent(event);
    };

    return {
      on: on,
      off: off,
      trigger: trigger
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.Event
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * @callback EventCallback
 * @param {...*} args
 */

/* Event class */

/* OnLoad class */

/**
 * This is OnLoad
 * @version 1.0
 * @namespace DGS.OnLoad
 * Moved to @demant/wdh onLoad
 */


DGS.OnLoad = function ($) {
  var instance,
      callbacks,
      components,
      hasRun = false;

  function init() {
    callbacks = [];
    components = [];

    var registerOnLoadCallback = function registerOnLoadCallback(callback) {
      if (!hasRun) {
        callbacks.push(callback);
      } else {
        try {
          callback();
        } catch (err) {
          console.log('Error', err);
        }
      }
    };

    $(document).ready(function () {
      hasRun = true;
      var callback, i, len;
      setTimeout(function () {
        len = callbacks.length;

        for (i = 0; i < len; i++) {
          callback = callbacks[i];

          try {
            callback();
          } catch (err) {
            console.log('Error', err);
          }
        }
      }, 0);
    });
    return {
      register: registerOnLoadCallback
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.OnLoad
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/* OnLoad class */

/* Dictionary class */

/**
 * This is OnContentLoaded
 * @version 1.0
 * @namespace DGS.Dictionary
 * Moved to @demant/wdh getDictionaryTexts
 */


DGS.Dictionary = function ($) {
  var instance;

  function init() {
    function getTexts(settings, callback, theLang) {
      var dictionary = [];
      $.each(settings, function (group, texts) {
        $.each(texts, function (key, text) {
          dictionary.push({
            key: "".concat(group, "/").concat(key),
            text: text
          });
        });
      });
      var url = theLang != null || theLang != undefined ? "".concat('/webservices/dictionary.svc/gettextslanguage' + '/').concat(theLang) : '/webservices/dictionary.svc/gettexts';
      $.post(url, JSON.stringify(dictionary), function (data) {
        $.each(data, function (index, value) {
          var keyPart = value.key.split('/');
          settings[keyPart[0]][keyPart[1]] = value.text;
        });
      }).done(function () {
        callback.apply();
      });
    }

    return {
      /**
             * Get texts from dictionary service
             * @memberof DGS.Dictionary
             */
      getTexts: getTexts
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.Dictionary
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/* Dictionary class */

/* OnLoadComplete */

/**
 * This is OnContentLoaded
 * @version 1.0
 * @namespace DGS.OnContentLoaded
 * Moved to @demant/wdh onContentLoaded
 */


DGS.OnContentLoaded = function ($) {
  var instance, callbacks, components;

  function init() {
    callbacks = [];
    components = [];

    var registerOnContentLoadedCallback = function registerOnContentLoadedCallback(callback) {
      callbacks.push(callback);
    };

    $(window).on('load', function () {
      var callback, i, len;
      setTimeout(function () {
        len = callbacks.length;

        for (i = 0; i < len; i++) {
          callback = callbacks[i];

          try {
            callback();
          } catch (err) {
            console.log('Error', err);
          }
        }
      }, 0);
    });
    return {
      register: registerOnContentLoadedCallback
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.OnContentLoaded
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/* OnLoadComplete */

/* Scroll class */

/**
 * This is Scroller
 * @version 1.0
 * @namespace DGS.Scroller
 * Moved to @demant/wdh onScroll
 */


DGS.Scroller = function ($) {
  var instance, callbacks;

  function init() {
    callbacks = [];

    var registerScrollCallback = function registerScrollCallback(callback) {
      callbacks.push(callback);
      callback($(window).scrollTop());
    };

    function handleScroll() {
      var callback,
          i,
          scrollTop = $(window).scrollTop(),
          len;

      for (i = 0, len = callbacks.length; i < len; i++) {
        callback = callbacks[i];
        callback(scrollTop);
      }
    }

    $(window).on('scroll', handleScroll);
    DGS.Resizer.getInstance().register(handleScroll);
    return {
      register: registerScrollCallback
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.Scroller
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/* Scroll class */

/* Overflow checker */

/**
 * This is Overflow
 * @version 1.0
 * @namespace DGS.Overflow
 * Moved to @demant/wdh registerFlowObject, checkFlowObject
 */


DGS.Overflow = function ($) {
  var instance, objects;

  function init() {
    objects = [];

    var registerOverflowObject = function registerOverflowObject($component, $target, forceFn) {
      var object = {
        component: $component,
        target: $target != undefined ? $target : $component,
        forceFn: forceFn,
        minWidth: 0
      };
      objects.push(object);
      checkObject(object);
    };

    function checkObject(object) {
      object.component.removeClass('content-overflow');
      var forceContentOverflow = object.forceFn != undefined ? object.forceFn() : false,
          rC,
          rT;
      rC = object.component.length ? object.component[0].getBoundingClientRect() : {
        top: 0,
        bottom: 0
      };
      rT = object.target.length ? object.target[0].getBoundingClientRect() : {
        top: 0,
        bottom: 0
      };
      var $bottomCheck = Math.ceil(rT.bottom) > Math.ceil(rC.bottom);
      var $topCheck = Math.floor(rT.top) < Math.floor(rC.top);

      if (object.component.hasClass(DGS.Overflow.getInstance().skipBottomCheck)) {
        $bottomCheck = false;
      }

      if (object.component.hasClass(DGS.Overflow.getInstance().skipTopCheck)) {
        $topCheck = false;
      }

      if (forceContentOverflow || $bottomCheck || $topCheck) {
        object.component.addClass('content-overflow');

        if (object.component.width() > object.minWidth) {
          object.minWidth = object.component.width();
        }
      }
    }

    function checkOverflow() {
      objects.forEach(function (object, index) {
        checkObject(object);
      });
    }

    DGS.Resizer.getInstance().register(checkOverflow);
    return {
      register: registerOverflowObject,
      check: checkOverflow,
      skipBottomCheck: 'skip-bottom-check',
      skipTopCheck: 'skip-top-check'
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.Overflow
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/* Equal heigh class */

/**
 * This is EqualHeight
 * @version 1.0
 * @namespace DGS.EqualHeight
 */


DGS.EqualHeight = function ($) {
  var instance, selectors, callbacks, callback;

  function init() {
    selectors = [];
    callbacks = [];

    var registerSelector = function registerSelector($selector, ignoreLineBreak, callback, outerHeight) {
      equalheight({
        selector: $selector,
        ignoreLineBreak: ignoreLineBreak,
        outerHeight: outerHeight
      });
      selectors.push({
        selector: $selector,
        ignoreLineBreak: ignoreLineBreak,
        outerHeight: outerHeight
      });

      if (callback != undefined) {
        callbacks.push(callback);
      } else {
        callbacks.push(function () {});
      }
    },
        runSelector = function runSelector($selector, ignoreLineBreak, callback, outerHeight) {
      var height = equalheight({
        selector: $selector,
        ignoreLineBreak: ignoreLineBreak,
        outerHeight: outerHeight
      });

      if (callback != undefined) {
        callback(height);
      }
    },
        equalheight = function equalheight(data) {
      var currentTallest = 0,
          currentRowStart = 0,
          rowDivs = [],
          $el,
          $li,
          topPosition = 0,
          currentDiv,
          $selector = data.selector,
          ignoreLineBreak = data.ignoreLineBreak,
          outerHeight = data.outerHeight;
      $selector.each(function () {
        $(this).height('auto');
      });
      $selector.each(function () {
        $el = $(this);
        $li = $el.closest('li');
        topPosition = $li.length ? $li.offset().top : $el.offset().top;

        if (currentRowStart != topPosition && ignoreLineBreak !== true) {
          for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
            var $li = $(rowDivs[currentDiv]).closest('li');
            $li.css('visibility', 'visible');
          }

          rowDivs.length = 0; // empty the array

          currentRowStart = topPosition;
          if (outerHeight) currentTallest = $el.outerHeight();else currentTallest = $el.height();
          rowDivs.push($el);
        } else {
          rowDivs.push($el);
          if (outerHeight) currentTallest = currentTallest < $el.outerHeight() ? $el.outerHeight() : currentTallest;else currentTallest = currentTallest < $el.height() ? $el.height() : currentTallest;
        }
      });

      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        if (outerHeight) {
          rowDivs[currentDiv].outerHeight(currentTallest);
        } else {
          rowDivs[currentDiv].height(currentTallest);
        }

        var $li = $(rowDivs[currentDiv]).closest('li');
        $li.css('visibility', 'visible');
      }

      return currentTallest;
    };

    function execute() {
      for (var i = 0, len = selectors.length; i < len; i++) {
        var height = equalheight(selectors[i]);
        callback = callbacks[i];
        callback(height);
      }
    }

    DGS.Resizer.getInstance().register(execute);
    return {
      register: registerSelector,
      trigger: runSelector
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.EqualHeight
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is EqualWidth
 * @version 1.0
 * @namespace DGS.EqualWidth
 */


DGS.EqualWidth = function ($) {
  var instance, selectors, callbacks, callback;

  function init() {
    selectors = [];
    callbacks = [];

    var registerSelector = function registerSelector(selector, callback) {
      equalwidth(selector);
      selectors.push(selector);

      if (callback != undefined) {
        callbacks.push(callback);
      } else {
        callbacks.push(function () {});
      }
    },
        equalwidth = function equalwidth(selector) {
      var maxWidth = 0;
      $(selector).each(function () {
        var $this = $(this),
            d;
        $this.css('width', 'auto');
        $this.attr('data-width', $(this).width());
        d = $this.attr('data-width');
        maxWidth = Math.max(maxWidth, d);
      });
      $(selector).width(maxWidth);
    };

    function execute() {
      for (var i = 0, len = selectors.length; i < len; i++) {
        equalwidth(selectors[i]);
        callback = callbacks[i];
        callback();
      }
    }

    DGS.Resizer.getInstance().register(execute);
    return {
      register: registerSelector
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.EqualWidth
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is Justify
 * @version 1.0
 * @namespace DGS.Justify
 */


DGS.Justify = function ($) {
  var instance,
      init = function init() {
    var applyJustifyLayout = function applyJustifyLayout($select, type) {
      $select.children(type).after('&nbsp; ');
      $select.append("<".concat(type, " style=\"max-height:0;line-height: 0; font-size: 0; width: 100% !important; display: inline-block;\"></").concat(type, ">"));
    };

    return {
      apply: applyJustifyLayout
    };
  };

  return {
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is PageClick
 * @version 1.0
 * @namespace DGS.PageClick
 */


DGS.PageClick = function ($) {
  var instance,
      callbacks,
      callbackData,
      init = function init() {
    callbacks = [];

    var registerCallback = function registerCallback(callback, $elementNotAffected) {
      setTimeout(function () {
        // push to end of execution, since we don't want it to evaluate immediately if registered on a click event
        callbackData = {
          callback: callback,
          $element: $elementNotAffected,
          active: true
        };
        callbacks.push(callbackData);
        registerMouseOver(callbackData);
      }, 0);
    },
        registerMouseOver = function registerMouseOver(cbData) {
      cbData.$element.mouseover(function () {
        cbData.active = false;
      });
      cbData.$element.mouseout(function () {
        cbData.active = true;
      });
    },
        deregisterCallback = function deregisterCallback(callback) {
      var spliceIndex = -1;
      callbacks.forEach(function (cbData, index) {
        if (cbData.callback === callback) {
          spliceIndex = index;
        }
      });

      if (spliceIndex > -1) {
        callbacks.splice(spliceIndex, 1);
      }
    };

    $('html').click(function (event) {
      callbacks.forEach(function (cbData) {
        if (cbData.active) {
          callbackData.callback.apply(null, [event]);
        }
      });
    });
    return {
      register: registerCallback,
      remove: deregisterCallback
    };
  };

  return {
    /**
         * Returns the current instance
         * @memberof DGS.PageClick
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is LoadComplete
 * @version 1.0
 * @namespace DGS.LoadComplete
 */


DGS.LoadComplete = function ($) {
  var instance, callbacks, callback;

  function init() {
    callbacks = [];

    var registerCallback = function registerCallback($component, callback) {
      $component.addClass('component-ready');
      DGS.showComponentsInViewport($(window).scrollTop());
      if (callback != undefined) callback();
    };

    return {
      register: registerCallback
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.LoadComplete
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is ImageComplete
 * @version 1.0
 * @namespace DGS.ImageComplete
 */


DGS.ImageComplete = function ($) {
  var instance, src;

  function init() {
    var registerImage = function registerImage($image, callback) {
      if ($image.length == 0) {
        callback();
      }

      if ($image[0].complete) {
        callback();
      } else {
        src = $image.attr('src');
        $image[0].onload = callback;
        $image.attr('src', src);
        setTimeout(function () {
          if ($image[0].complete) {
            callback();
          }
        }, 100);
      }
    };

    return {
      register: registerImage
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.ImageComplete
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is WaitForLoad
 * @version 1.0
 * @namespace DGS.WaitForLoad
 */


DGS.WaitForLoad = function ($) {
  var instance;

  function init() {
    var register = function register($component, useAutoTimeOut) {
      $component.removeClass('component-ready');
      $component.addClass('component-wait');

      if (useAutoTimeOut !== false) {
        setTimeout(function () {
          if (!$component.hasClass('component-ready')) {
            $component.addClass('component-failed');
            DGS.LoadComplete.getInstance().register($component);
          }
        }, 5000);
      }
    };

    return {
      register: register
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.WaitForLoad
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);
/**
 * This is DrawSeparators
 * @version 1.0
 * @namespace DGS.DrawSeparators
 */


DGS.DrawSeparators = function ($) {
  var instance, targets;

  function init() {
    targets = [];

    var register = function register($list, styleId) {
      targets.push({
        $list: $list,
        styleId: styleId
      });
      drawSeparators($list, styleId);
    },
        drawSeparators = function drawSeparators($list, styleId) {
      /* parentSelector = '.news-list-spot ul';
          childSelector = 'li';
          styleId = '#news-list-spot-style'; //'#wrapper .news-list-spot .component-content ul li' */
      // var styleQuery = parentSelector + " " + childSelector;
      // $parent.each(function(){
      // var $that = $(this),
      // $li = $(childSelector, $that),
      var lastDim,
          dim,
          $this,
          space,
          $style,
          hasWrittenStyle = false,
          q,
          s,
          bs,
          re = /(rgb[a]*\(([0-9]{1,3},* *){3}0*\.*[0-9]*\))/,
          m,
          color = 'rgb(228, 228, 228)';
      $list.addClass(styleId);
      $list.each(function () {
        $this = $(this);
        dim = $this.offset();
        dim.width = $this.outerWidth();
        dim.height = $this.outerHeight();

        if (lastDim != undefined) {
          space = dim.left - (lastDim.left + lastDim.width);

          if (space > 0 && !hasWrittenStyle) {
            $this.attr('data-space', "".concat(space / 2, "px"));
            $style = $("#".concat(styleId));

            if ($style.length) {
              $style.remove();
            }

            s = window.getComputedStyle($list[$list.length - 1], ':after');
            bs = s.getPropertyValue('box-shadow');

            if ((m = re.exec(bs)) !== null) {
              if (m.index === re.lastIndex) {
                re.lastIndex++;
              }

              color = m.length ? m[0] : color;
            }

            $('head').append("<style type=\"text/css\" id=\"".concat(styleId, "\">.").concat(styleId, ":not(:first-child)::after { box-shadow: -").concat(space / 2, "px 0px 0px 0px ").concat(color, " !important; }</style>"));
            hasWrittenStyle = true;
          }
        }

        lastDim = dim;
      }); // });
    },
        execute = function execute() {
      for (var i = 0, len = targets.length; i < len; i++) {
        drawSeparators(targets[i].$list, targets[i].styleId);
      }
    };

    DGS.Resizer.getInstance().register(execute);
    return {
      register: register
    };
  }

  return {
    /**
         * Returns the current instance
         * @memberof DGS.DrawSeparators
         */
    getInstance: function getInstance() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
}(jQuery);

window.DGS = DGS;

(function ($) {
  DGS.showComponentsInViewport = function (windowScroll) {
    var breakOut = false;
    setTimeout(function () {
      $('#wrapper #content .component, #wrapper #footer').each(function () {
        if (breakOut) return;

        var $this = $(this),
            offset = $this.offset(),
            wh = $(window).height(),
            id = $this.attr('id'),
            showComponent = function showComponent($component) {
          if (!$component.hasClass('animate')) {
            $component.addClass('animate');
            var trackingName = $component.attr('data-tracking-name');

            if (trackingName != undefined) {
              DGS.Event.getInstance().trigger('trackElementReached', trackingName);
            }
          }
        };

        if (($this.hasClass('component-ready') || $this.attr('id') == 'footer') && offset.top - wh < windowScroll) {
          showComponent($this);
        } else if ($this.hasClass('link-to-previous')) {
          showComponent($this);
        }
      });
    }, 0);
  }; // Moved to @demant/wdh isPageEditor


  DGS.isPageEditor = function () {
    return $('body').hasClass('on-page-editor');
  }; // Not used


  DGS.getConfiguration = function () {
    var script = document.currentScript || function () {
      var scripts = document.getElementsByTagName('script[type="application/json"]');
      return scripts[scripts.length - 1];
    }(),
        configurationId,
        $configuration,
        configuration;

    configurationId = $(script).data('configuration-id');
    $configuration = configurationId !== undefined ? $("[data-configuration='".concat(configurationId, "']")) : undefined;
    configuration = $configuration !== undefined && $configuration.length ? JSON.parse($configuration.html()) : {};
    return configuration;
  }; // Moved to @demant/wdh getQueryParameter


  DGS.getQueryParameter = function (name, url) {
    if (!url) url = window.location.href;
    url = url.toLowerCase(); // This is just to avoid case sensitiveness

    name = name.replace(/[\[\]]/g, '\\$&').toLowerCase(); // This is just to avoid case sensitiveness for query parameter name

    var regex = new RegExp("[?&]".concat(name, "(=([^&#]*)|&|#|$)")),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }; // Moved to @demant/wdh getLocationHash


  DGS.getLocationHash = function () {
    return window.location.hash != '' ? window.location.hash.substr(1) : '';
  };

  DGS.DESKTOP_LAYOUT = 'desktop';
  DGS.TABLET_LAYOUT = 'tablet';
  DGS.MOBILE_LAYOUT = 'mobile'; // Moved to @demant/wdh getLayoutType

  DGS.getLayoutType = function () {
    if (window.matchMedia('(max-width: 767px)').matches) {
      return DGS.MOBILE_LAYOUT;
    }

    if (window.matchMedia('(max-width: 1199px)').matches) {
      return DGS.TABLET_LAYOUT;
    }

    return DGS.DESKTOP_LAYOUT;
  };

  DGS.isIE = function () {
    var ua = window.navigator.userAgent,
        msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }

    return false;
  };

  DGS.useCustomSelect = function (callback) {
    var decorateSelectsWithNumberOfChildren = function decorateSelectsWithNumberOfChildren($selects) {
      var numSelectOptions, $this;
      if (!$selects) return;
      $selects.each(function () {
        $this = $(this);
        numSelectOptions = $this.children().length;

        if (numSelectOptions > 5) {
          $this.addClass('more-than-five');
        }

        if (numSelectOptions > 10) {
          $this.addClass('more-than-ten');
        }

        if (numSelectOptions > 15) {
          $this.addClass('more-than-fifteen');
        }

        if (numSelectOptions > 20) {
          $this.addClass('more-than-twenty');
        }
      });
    },
        postProcessDropDowns = function postProcessDropDowns() {
      $('.nice-select > ul.list').wrap('<div></div>');
      $('.nice-select > div').addClass('list');
      $('.nice-select > .list > ul.list').removeClass('list');
    },
        preProcessDropDowns = function preProcessDropDowns($selects) {
      decorateSelectsWithNumberOfChildren($selects);
      $('.nice-select > div.list > ul').unwrap();
      $('.nice-select > ul').addClass('list');
    };

    DGS.Event.getInstance().on(window.DGS.Events.SELECT_ADDED, function ($selects) {
      if (!DGS.UserAgent.getInstance().mobileTabletCheck()) {
        preProcessDropDowns($selects);
        $selects.niceSelect();
        postProcessDropDowns();
      }

      if (callback) {
        callback.apply();
      }
    });
    DGS.Event.getInstance().on(window.DGS.Events.SELECT_UPDATED, function ($selects) {
      if (!DGS.UserAgent.getInstance().mobileTabletCheck()) {
        preProcessDropDowns($selects);
        $selects.niceSelect('update');
        postProcessDropDowns();
      }
    });
  };

  DGS.setLocationHash = function (value) {
    window.location.hash = value;
  };

  DGS.OnLoad.getInstance().register(function () {
    // DECORATE IF IS INTERNET EXPLORER
    if (DGS.isIE()) {
      $('#wrapper').addClass('ie');
    } // ANCHOR LINKING LOGIC PART 1
    // REMOVE _ POSTFIX FROM ANCHORS


    $('#wrapper .submenu-point-spot a').each(function () {
      var name = $(this).attr('name');
      $(this).attr('name', name.substr(0, name.length - 1));
    }); // ANCHOR LINKING LOGIC PART 1 - end

    $('#wrapper > #content .component:not(.component-wait)').each(function () {
      DGS.LoadComplete.getInstance().register($(this));
    });
    DGS.Scroller.getInstance().register(DGS.showComponentsInViewport); // DECORATE COMPONENTS PRECEEDED BY COMPONENTS WITH THE SAME BACKGROUND COLOR

    var prevBackground = null;
    $('#content .grid-12 > .component, #footer .grid-12 > .component').each(function () {
      var $component = $(this),
          background = $component.css('background-color');

      if ($component.hasClass('intro-banner') || $component.hasClass('inoborder') || // text image spot variation
      $component.hasClass('rich-text--overlay') || $component.hasClass('overlay') // rich text variation
      ) {
        // move on
        prevBackground = null;
        return;
      }

      if (prevBackground && prevBackground == background) {
        $component.addClass('repeating-background');
      }

      prevBackground = background;
    });
  });
  DGS.OnContentLoaded.getInstance().register(function () {
    // ANCHOR LINKING LOGIC PART 2
    var hash = window.location.hash,
        $components = $('#content .component'),
        $target,
        $component,
        hashInterval,
        isReady,
        skip;

    if (hash == undefined || hash == null || hash == '') {
      hash = null; // FIND DEFINED PLACES TO SCROLL TO IF NO HASH IS GIVEN IN QUERY

      $('.submenu-point-spot.scroll-to-onload a').each(function () {
        hash = "#".concat($(this).attr('name'));
      });
    }

    if (hash) {
      // Here are anchor links using the id attribute
      if ($(hash).length > 0) {
        setTimeout(function () {
          if ($(hash).offset().top > 0) {
            var $header = $('.header'); // Calculating the height of the header div, if it's sticky

            var headerPosition = $header.css('position');
            var headerHeight = 0;

            if (headerPosition === 'static' || headerPosition === 'fixed') {
              headerHeight = $header.outerHeight();
            }

            $('html, body').animate({
              scrollTop: $(hash).offset().top - headerHeight
            }, 200);
          }
        }, 200); // This is the time we wait for the components to load
      } // Here the anchor links with using the name attribute


      $target = $("a[name=".concat(hash.substr(1), "]"));
      $component = $target.length ? $target.closest('.component') : undefined;

      if ($component != undefined) {
        hashInterval = setInterval(function () {
          isReady = true;
          skip = false;
          $components.each(function () {
            if (!skip) {
              if ($(this).html() == $component.html()) {
                skip = true;

                if (isReady) {
                  // clear interval
                  clearInterval(hashInterval); // do scroll

                  var position = $target.position(),
                      top = position ? position.top : null;

                  if (top != null) {
                    window.scrollTo(0, top);
                  }
                }
              } else if (!$(this).hasClass('component-ready')) {
                isReady = false;
              }
            }
          });
        }, 100);
      }
    } // ANCHOR LINKING LOGIC PART 2 - end

  });
  DGS.Resizer.getInstance().register(function () {
    window.DGS.Resizer.getInstance().register(function () {
      $('.component.size-to-fit-headline').each(function () {
        // detect if word will not fit available space and resize the font if needed
        var $component = $(this);
        $('h1, h2, h3, h4, h5', $component).each(function (i, box) {
          var width = $(box).width(),
              html = '<span></span>',
              line = '',
              n = 100;

          if ($(box).has('span').length) {
            line = $(box).children()[0];
          } else {
            line = $(box).wrapInner(html).children()[0];
          }

          if ($(line).width() > width) {
            $(box).css('font-size', n);

            while ($(line).width() > width) {
              $(box).css('font-size', --n);
            }

            $(box).text($(line).text());
          }
        });
      });
    });
  });
})(jQuery);