function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

var ko = require("./knockout-310"); // No more "global" variables, all needs to be defined


var dataColourSelector;
var ColourSelector;
var PopUpColours;

function colourSelector() {
  var self = this;
  self.sharedText = ko.observable(dataColourSelector.sharedText);
  self.style = ko.observable(dataColourSelector);
  self.variation = ko.observable(self.style().variations[0]);

  self.dataSet = function (dataSet) {
    self.sharedText(dataSet.sharedText);
    self.style(dataSet);
    self.variation(self.style().variations[0]);
  }; //clicking on colour


  self.variationSelect = function (data, e) {
    self.variation(data);
  };

  self.hasColours = function (toCheck) {
    return Object.keys(toCheck).length > 0 && toCheck.preview != "..null" && toCheck.preview != null && toCheck.imageLarge != "..null" && toCheck.imageLarge != null ? true : false;
  };

  self.computedVariations = ko.computed(function () {
    var doNotHide = true;
    var variationList = self.style();

    if (variationList != undefined) {
      jQuery(variationList.variations).each(function (index, val) {
        var hex = val.colour.colourHex1;
        doNotHide = hex == "" ? false : true;
      });
    }

    return doNotHide;
  });

  self.openVariationSelectorOverview = function () {
    if (window.matchMedia("(min-width: 845px)").matches) {
      PopUpColours.popupStatus(true);
    }
  }; //luminosity emphasizer


  ko.bindingHandlers.emphasizeColor = {
    update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
      function Brightness(rgb) {
        return Math.sqrt(rgb.R * rgb.R * .241 + rgb.G * rgb.G * .691 + rgb.B * rgb.B * .068);
      }

      function hex2rgb(hex) {
        var r = '0x' + hex[1] + hex[2] | 0;
        var g = '0x' + hex[3] + hex[4] | 0;
        var b = '0x' + hex[5] + hex[6] | 0;
        return {
          R: r,
          G: g,
          B: b
        };
      }

      function rgbToHex(r, g, b) {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      }

      var rgb = undefined;
      var maxBright = 24;
      var brightness = 35;
      var accessor = valueAccessor();
      var value = jQuery(accessor.splice(0, 2));
      /*
      console.info("new block")
      console.log(self.variation());
      console.log(accessor[0]);*/

      if (accessor[0] == ko.unwrap(accessor[1])) {
        jQuery(element).css({
          backgroundColor: "transparent"
        });
      } else {
        value.each(function (i, v) {
          if (v) {
            rgb = hex2rgb(v);
            brightness = 256 - Math.floor(Brightness(rgb));

            if (brightness < maxBright) {
              jQuery(element).parent().css({
                borderColor: "#c2c2c2"
              }).addClass("brightness");
              jQuery(element).css({
                backgroundColor: "#c2c2c2"
              });
              return;
            }
          }
        });
      }
    }
  }; //image swaps

  var cloneGrandpa = jQuery(new Object());
  ko.bindingHandlers.imageSwapFade = {
    init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var temp1 = valueAccessor(); //console.warn("--->",temp1)

      var temp2 = ko.utils.unwrapObservable(temp1[0]);
      var temp3 = temp1[1];
      var value = temp2[temp3]; //console.warn(temp2,temp3,value)

      jQuery(element).attr("src", value);
    },
    update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var temp1 = valueAccessor(); //console.warn("--->",temp1)

      var temp2 = ko.utils.unwrapObservable(temp1[0]);
      var temp3 = temp1[1];
      var temp4 = temp1[2];
      var value = temp2[temp3];

      if (value != jQuery(element).attr("src")) {
        var speed = 1; //It's alive!

        var cloneBaby = jQuery(document.createElement("img"));
        cloneBaby.attr({
          src: jQuery(element).attr("src")
        });
        cloneBaby.attr("class", temp4);
        cloneBaby.width(jQuery(element).width()).height(jQuery(element).height());
        cloneGrandpa = cloneBaby;
        jQuery(element).parent().append(cloneBaby); //killing tweens is just good manners, son.

        TweenMax.killTweensOf(element);
        jQuery(element).attr("src", value);
        TweenMax.to(cloneBaby, speed, {
          opacity: 0,
          onComplete: function onComplete() {
            cloneBaby.remove();
          },
          ease: "Expo.easeOut"
        });
      }
    }
  };

  self.variationSelectNextPrev = function (data, e) {
    var index = ko.utils.arrayIndexOf(self.style().variations, self.variation()) + this;

    if (index >= 0 && index <= self.style().variations.length - 1) {
      var variationNextPrev = self.style().variations[index];
      self.variation(variationNextPrev);
      /*$.event.trigger({
      caller: "Event: Product Tool changeData variationSelect",
      type: "updateAnalytics",
      effect: "event", toaster: "orange",
      datamodel: {
          category:	"Change of Variation",
          action:		"Navigation Click",
          label:		self.style().name+" variation chosen: "+variationNextPrev.colour.colourName,
          value: undefined,
          page:	{
              title:	"Product Tool H1 2015",
              page:	(self.locationURL+self.product().family+"/"+self.style().name+"/colour_selector_popup")
          }
          },
      description: "updating analytics with event"
      });*/
    }
  }; //clicking on arrows


  self.deviceSelection = function (data, e) {
    var index = ko.utils.arrayIndexOf(self.styleListUnsorted(), self.style()) + this;

    if (index >= 0 && index <= self.styleListUnsorted().length - 1) {
      var row = Math.floor(index / 4);
      self.setDevice(self.styleListUnsorted()[index], row);
    }
  };
}
/*popup*/


var popUpColours = function popUpColours() {
  var self = this;
  self.popup = jQuery(".colour-selector:last .internal_full_popup");
  self.popupStatus = ko.observable(false);
  self.importedData = ColourSelector;
  ko.bindingHandlers.scrollList = {
    //create bauble on init
    init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var el = $(element);
      var elParent = el.parent(); //el.bauble({align: "right", align2: "top", direction: "right", x:12, y: Math.ceil((el.height()/2)+5),size: 8,color:"#c6168d",objClass:"bg_arrow"});
    },
    update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var updateList = self.importedData.variation();
      setTimeout(function () {
        var el = $(element);
        var elParent = el.parent();

        if (el.children().children().children("span").hasClass("selected")) {
          var pos = elParent.scrollTop() + el.position().top - elParent.height() / 2 + el.height() / 2;
          TweenMax.to(elParent, 0.2, {
            scrollTo: {
              y: pos
            },
            overwrite: "all"
          });
          /*console.log(pos);
          console.log(elParent);
          console.log('elParent.scrollTop() : ', elParent.scrollTop());
          console.log('el.position().top : ', el.position().top);
          console.log('elParent.height()/2 : ', elParent.height()/2);
          console.log('el.height()/2 : ', el.height()/2);
          console.log('pos : ',pos);
          elParent.scrollTop = pos;*/
        }
      }, 0);
    }
  };
  ko.bindingHandlers.fadePopup = {
    //create bauble on init
    init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var el = $(element);
      el.fadeOut(0);
    },
    update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
      var el = $(element);

      if (ko.unwrap(valueAccessor())) {
        el.fadeIn(400);
      } else {
        el.fadeOut(200);
      }
    }
  };

  self.toggle = function () {
    self.popupStatus(!self.popupStatus);
  };
};

var updateColourSelector = function updateColourSelector(data) {
  if (_typeof(ColourSelector) == undefined || typeof ColourSelector == 'undefined') {
    dataColourSelector = data;
    ColourSelector = new colourSelector();
    ko.applyBindings(ColourSelector, jQuery(".colour-selector:last .column-right").get(0));
    PopUpColours = new popUpColours();
    ko.applyBindings(PopUpColours, jQuery(".colour-selector .popup:last").get(0));
  } else {
    ColourSelector.dataSet(data);
  }
};

$(document).ready(function () {
  if (_typeof(dataColourSelector) !== undefined && typeof dataColourSelector !== 'undefined') {
    ColourSelector = new colourSelector();
    ko.applyBindings(ColourSelector, jQuery(".colour-selector:last .column-right").get(0));
    PopUpColours = new popUpColours();
    ko.applyBindings(PopUpColours, jQuery(".colour-selector .popup:last").get(0));
  }
});
export default updateColourSelector;