(function ($) {
  function customDatePicker(options) {
    var $this = $(this),
        tmpFrom,
        tmpTo; // add html to page

    $this.wrap('<div class="custom-datepicker"></div>');
    $this.prop('disable', true);
    $this.after('<div class="cd-container"><div class="cd-calendar"></div><div class="cd-range-container"><div class="cd-ranges"></div><div class="cd-date"></div><div class="cd-ctas"></div></div></div>');

    var options = options || {},
        getOption = function getOption(property, defaultValue) {
      if (options.hasOwnProperty(property)) {
        return options[property];
      }

      return defaultValue;
    },
        saveDate = function saveDate(type, expr) {
      var d = new Date();

      if (expr.indexOf('mo') > -1) {
        if (type == "from") {
          d.setMonth(d.getMonth() + parseInt(expr));
          d.setDate(1);
        } else {
          d.setMonth(d.getMonth() + parseInt(expr) + 1);
          d.setDate(1);
          d.setDate(d.getDate() - 1);
        }
      } else if (expr.indexOf('d') > -1 && type == 'from') {
        d.setDate(d.getDate() + parseInt(expr));
      } else if (expr.indexOf('m') > -1 && type == 'from') {
        d.setMonth(d.getMonth() + parseInt(expr));
      } else if (expr.indexOf('y') > -1 && type == 'from') {
        d.setYear(d.getYear() + parseInt(expr));
      }

      switch (type) {
        case 'from':
          tmpFrom = d;
          break;

        case 'to':
          tmpTo = d;
          break;
      }
    },
        unsetAllRangeButtons = function unsetAllRangeButtons($container) {
      $('.cd-range-button', $container).removeClass('active');
    },
        setTmpDateFields = function setTmpDateFields() {
      if (tmpFrom > tmpTo) {
        var tmpToD = tmpFrom;
        tmpFrom = tmpTo;
        tmpTo = tmpToD;
      }

      $('.cd-date-container:nth-child(1) .cd-date-value').text(tmpFrom.dateFormat(options.dateFormat));
      $('.cd-date-container:nth-child(2) .cd-date-value').text(tmpTo.dateFormat(options.dateFormat));
    },
        saveDataValue = function saveDataValue() {
      $this.data('from-value', tmpFrom);
      $this.data('to-value', tmpTo);
    },
        shortcuts = getOption('shortcuts', []),
        expr = getOption('expr', "-1m"),
        fromDateLabel = getOption('fromDateLabel', 'From'),
        toDateLabel = getOption('toDateLabel', 'To'),
        applylabel = getOption('applyLabel', 'Apply'),
        cancelLabel = getOption('cancelLabel', 'Cancel'),
        $container = $this.parent(),
        $cdContainer = $this.next(),
        $cdRange = $('.cd-ranges', $cdContainer),
        $cdDate = $('.cd-date', $cdContainer),
        $cdCtas = $('.cd-ctas', $cdContainer),
        $cdCalendar = $('.cd-calendar', $cdContainer); // open first dialog and preset date to one of the three options


    $cdContainer.hide();
    shortcuts.forEach(function (val) {
      var label = val.label,
          expr = val.expr;
      $cdRange.append('<a class="cd-range-button" data-expr="' + expr + '">' + label + '</a>');
    });
    $cdDate.append('<div class="cd-date-container"><div class="cd-date-label">' + fromDateLabel + '</div><div class="cd-date-value" data-type="from"></div></div>');
    $cdDate.append('<div class="cd-date-container"><div class="cd-date-label">' + toDateLabel + '</div><div class="cd-date-value" data-type="to"></div></div>');
    $cdDate.append('<div class="clearfix"></div>');
    $cdCtas.append('<div class="cd-ctas-container"><div class="cd-cta-apply">' + applylabel + '</div><div class="cd-cta-cancel"> ' + cancelLabel + '</div></div> </div>');
    $this.click(function () {
      $cdContainer.toggle();
      $this.blur();
    });
    $('.cd-cta-cancel', $container).click(function () {
      $cdContainer.hide();
    });
    $('.cd-cta-apply', $container).click(function () {
      $this.val(tmpFrom.dateFormat(options.dateFormat) + '  -  ' + tmpTo.dateFormat(options.dateFormat));
      $cdContainer.hide();
      saveDataValue();
    });
    $('.cd-date-value', $container).click(function () {
      // open date picker
      var type = $(this).attr('data-type');
      $cdCalendar.datepicker("destroy");
      $cdCalendar.datepicker({
        onSelect: function onSelect(selectedDate) {
          var millis = Date.parse(selectedDate),
              d = new Date();
          d.setTime(millis);

          switch (type) {
            case 'to':
              tmpTo = d;
              break;

            case 'from':
              tmpFrom = d;
              break;
          }

          setTmpDateFields();
          saveDataValue();
          $cdCalendar.hide();
          $cdContainer.removeClass('hasDatepicker');
          unsetAllRangeButtons($container);
        }
      });
      $cdCalendar.datepicker("setDate", type == "from" ? tmpFrom : tmpTo);
      $cdCalendar.show();
      $cdContainer.addClass('hasDatepicker');
    });
    $('.cd-range-button', $container).click(function () {
      var $this = $(this),
          expr = $this.attr('data-expr');
      saveDate('from', expr);
      saveDate('to', expr);
      setTmpDateFields();
      unsetAllRangeButtons($container);
      $this.addClass('active');
    });
    saveDate('from', expr);
    saveDate('to', expr);
    setTmpDateFields();
    $this.val(tmpFrom.dateFormat(options.dateFormat) + '  -  ' + tmpTo.dateFormat(options.dateFormat));
    saveDataValue();
  }

  $.fn.extend({
    customDatePicker: customDatePicker,
    customDatePickerFrom: function customDatePickerFrom() {
      return $(this).data('from-value');
    },
    customDatePickerTo: function customDatePickerTo() {
      return $(this).data('to-value');
    }
  });
})(jQuery);