function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function ($) {
  "use strict";

  window.DGS.OnContentLoaded.getInstance().register(function () {
    $('.component.notification-overlay').each(function () {
      var vm = $(this);
      $('a').click(function (e) {
        var thisa = $(this);
        var thisattr = $(this).attr('href');

        if (_typeof(thisattr) !== (typeof undefined === "undefined" ? "undefined" : _typeof(undefined)) && thisattr !== false) {
          e.preventDefault();
          vm.addClass('shown');
          setTimeout(function () {
            location.href = thisattr;
          }, 2000);
        }
      });
    });
  });
})(jQuery);