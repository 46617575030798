$(function () {
  'use strict';

  $('html').flowtype({
    minimum: 320,
    maximum: 2400,
    minFont: 12,
    maxFont: 20,
    fontRatio: 30
  });
});