(function ($) {
  window.DGS.CenterSpotConfig = {
    mapBrowserWidth: false
  };
  window.DGS.OnLoad.getInstance().register(function () {
    var $thisSpot = $('.center-list-spot');

    if ($thisSpot.length) {
      var $breadcrumb = $('.breadcrumb');

      if ($breadcrumb.length) {
        var centerRegion = $('.center-list-region').contents()[0].data.trim(),
            centerRegionName = $('#region-item-name').text();
        var links = $breadcrumb.find('a[title="*"]'); // Changing region wildcards to actual region name

        $(links[0]).text(centerRegion);
        $(links[0]).prop('title', centerRegion);
        var centerRegionLink = $(links[0]).prop('href');

        if (typeof centerRegionLink !== 'undefined') {
          centerRegionLink = centerRegionLink.replace(',-w-,', centerRegionName);
          $(links[0]).prop('href', centerRegionLink.replace(/\/$/, ''));
        }
      } // letter scrolling


      $('.alphabet-letter').click(function (e) {
        e.preventDefault();

        if ($("#alphabet-".concat($(this).attr('value')))) {
          $('html, body').animate({
            scrollTop: $("#alphabet-".concat($(this).attr('value'))).offset().top - $('#header').outerHeight()
          }, 500);
        }
      });
      $('.back-to-top').click(function (e) {
        e.preventDefault();
        var anchor = '#content';

        if ($('#alphabet').length) {
          anchor = '#alphabet';
        } else if ($('.regions').length) {
          anchor = '.regions';
        }

        $('html, body').animate({
          scrollTop: $(anchor).offset().top - $('#header').outerHeight()
        }, 500);
      });
    }
  });
})(jQuery);