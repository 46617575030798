(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var animateOut = function animateOut($target, callback) {
      var winWidth = $(window).width() + $target.width() + 200,
          t = new TimelineMax();
      t.to($target, 0.8, {
        x: -400,
        ease: Power2.easeOut
      });
      t.to($target, 0.6, {
        alpha: 0,
        ease: Power2.easeOut
      }, '-=.8');
      t.set($target, {
        display: 'none'
      });
      t.call(callback);
    },
        animateIn = function animateIn($target) {
      var winWidth = $(window).width() + $target.width() + 200,
          t = new TimelineMax();
      t.set($target, {
        x: 400,
        alpha: 0
      });
      t.set($target, {
        display: 'block'
      });
      t.to($target, 0.8, {
        x: 0,
        ease: Power2.easeOut
      });
      t.to($target, 0.8, {
        alpha: 1,
        ease: Power2.easeOut
      }, '-=.6');
    };

    $('#wrapper .screening-test-spot').each(function () {
      var $spot = $(this),
          $startTest = $('.start-test', $spot),
          $questionBtns = $('.questions li .button.no-icon', $spot),
          $screens = $('.questions-intro, .questions > ul > li:not(.screening-test-result-container), .screening-test-result, .component-content', $spot),
          currentScore = 0;
      window.DGS.EqualHeight.getInstance().register($screens, true);
      $startTest.click(function (e) {
        e.preventDefault();
        var $firstQuestion = $('.questions li:first-child'),
            trackingKey = $firstQuestion.data('tracking-key');
        animateOut($('.questions-intro', $spot), function () {
          animateIn($firstQuestion);
        });
      });
      $questionBtns.click(function (e) {
        e.preventDefault();
        var $next = $(this).parent().next();
        currentScore += parseInt($(e.target).data('score'));
        animateOut($(this).parent(), function () {
          if ($next.hasClass('screening-test-result-container')) {
            $('.screening-test-result', $next).each(function () {
              var minScore = $(this).data('min-score'),
                  maxScore = $(this).data('max-score');

              if (parseInt(minScore) <= currentScore && currentScore <= parseInt(maxScore)) {
                animateIn($(this));
                $next.show();
              }
            });
          } else {
            animateIn($next);
          }
        });
      });
    });
  });
})(jQuery);

(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var animateOut = function animateOut($target, callback) {
      var winWidth = $(window).width() + $target.width() + 200,
          t = new TimelineMax();
      t.to($target, .8, {
        x: -400,
        ease: Power2.easeOut
      });
      t.to($target, .6, {
        alpha: 0,
        ease: Power2.easeOut
      }, "-=.8");
      t.set($target, {
        display: "none"
      });
      t.call(callback);
    },
        animateIn = function animateIn($target) {
      var winWidth = $(window).width() + $target.width() + 200,
          t = new TimelineMax();
      t.set($target, {
        x: 400,
        alpha: 0
      });
      t.set($target, {
        display: "block"
      });
      t.to($target, .8, {
        x: 0,
        ease: Power2.easeOut
      });
      t.to($target, .8, {
        alpha: 1,
        ease: Power2.easeOut
      }, "-=.6");
    },
        track = function track(trackingKey) {
      if (typeof console != 'undefined') console.debug(trackingKey);
    };

    $('#wrapper .screening-test-spot').each(function () {
      var $spot = $(this),
          $startTest = $('.start-test', $spot),
          $questionBtns = $('.questions li .button.no-icon', $spot),
          $screens = $('.questions-intro, .questions > ul > li:not(.screening-test-result-container), .screening-test-result', $spot),
          currentScore = 0;
      window.DGS.EqualHeight.getInstance().register($screens, true);
      $startTest.click(function (e) {
        e.preventDefault();
        var $firstQuestion = $('.questions li:first-child'),
            trackingKey = $firstQuestion.data('tracking-key');
        animateOut($('.questions-intro', $spot), function () {
          track(trackingKey);
          animateIn($firstQuestion);
        });
      });
      $questionBtns.click(function (e) {
        e.preventDefault();
        var $next = $(this).parent().next();
        currentScore += parseInt($(e.target).data('score'));
        animateOut($(this).parent(), function () {
          if ($next.hasClass('screening-test-result-container')) {
            $('.screening-test-result', $next).each(function () {
              var minScore = $(this).data('min-score'),
                  maxScore = $(this).data('max-score');

              if (parseInt(minScore) <= currentScore && currentScore <= parseInt(maxScore)) {
                track($(this).data('tracking-key'));
                animateIn($(this));
                $next.show();
              }
            });
          } else {
            track($next.data('tracking-key'));
            animateIn($next);
          }
        });
      });
    });
  });
})(jQuery);