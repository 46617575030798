import { ComponentBootstrap } from '@demant/wdh-react-bootstrap';
import HeaderApp from './components/header-app';
var header = new ComponentBootstrap('header', '', 'header', [{
  name: 'linkList',
  element: '.link-list .component-content ul',
  type: 'linkList'
}, {
  name: 'navigation',
  element: '.navigation .component-content ul',
  type: 'navigation'
}, {
  name: 'image',
  element: '.image img',
  type: 'image'
}, {
  name: 'imageLink',
  element: '.image a',
  type: 'link'
}, {
  name: 'searchFieldSpot',
  element: '.search-field-spot',
  type: 'searchFieldSpot'
}, {
  name: 'languageSelector',
  element: '.language-selector-spot',
  type: 'languageSelectorSpot'
}]);
header.reactApp = HeaderApp;
header.run();