(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    function distributeItems() {
      $('.contact-information-item-group').each(function () {
        var $this = $(this),
            docWidth = $this.width(),
            $li = $('li', $this),
            liWidth = $li.width(),
            prLine = Math.floor(docWidth / liWidth),
            excessWidth = prLine > 0 ? Math.floor((docWidth - prLine * liWidth) / (prLine - 1)) - 1 : 0,
            i = 0;
        $li.each(function () {
          if (i % prLine == 0) {
            $(this).css('clear', 'both');
          } else {
            $(this).css('clear', 'none');
          }

          if (excessWidth > 0) {
            if (i % prLine < prLine - 1) {
              $(this).css('margin-right', excessWidth + 'px');
            } else {
              $(this).css('margin-right', '0px');
            }
          } else {
            $(this).css('margin-right', '0px');
          }

          i++;
        });
      });
    }

    ;
    window.DGS.EqualWidth.getInstance().register(".contact-information-item-group li", distributeItems);
  });
})(jQuery);

(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    var showStateForSpot = function showStateForSpot(targetState, items, $ul) {
      var i;

      for (i = 0; i < items.length; i++) {
        var $this = items[i],
            state = $this.attr('data-state');

        if (state == targetState) {
          $ul.append($this);
        } else {
          $this.remove();
        }
      }

      window.DGS.Resizer.getInstance().execute();
    };

    $('#wrapper .contact-information.group-by-state').each(function () {
      var $spot = $(this),
          $ul = $('ul', $spot),
          $li = $('li', $spot),
          states = [],
          $items = [],
          $selector = $('<select><option>- Select -</option></select>');
      $li.each(function () {
        var $this = $(this),
            state = $this.attr('data-country');
        $this.attr('data-state', state);

        if (states.indexOf(state) === -1) {
          states.push(state);
          $selector.append('<option>' + state + '</option>');
        }

        $items.push($this);
        $this.remove();
      });
      showStateForSpot('', $items, $ul);
      $selector.change(function () {
        showStateForSpot($selector.val(), $items, $ul);
      });
      $selector.insertAfter($('.contact-information-item-group h3', $spot));
    });
  });
})(jQuery);