var loadImage = function loadImage(item) {
  var dataSource = item.getAttribute('data-src');

  if (dataSource) {
    item.setAttribute('src', dataSource);
  }
};

var isMobile = window.matchMedia('screen and (max-width: 767px)').matches;

var defaultPreElement = function defaultPreElement() {
  var flexSpots = document.querySelectorAll('.flex-spot');
  flexSpots.forEach(function (flexSpot) {
    var preElementDesktopImage = flexSpot.querySelector('.pre-element .pre-element__item__image[data-tag*="desktop"] img');
    var preElementMobileImage = flexSpot.querySelector('.pre-element .pre-element__item__image[data-tag*="mobile"] img');

    if (isMobile && preElementMobileImage) {
      loadImage(preElementMobileImage);
    } else if (!isMobile && preElementDesktopImage) {
      loadImage(preElementDesktopImage);
    }
  });
};

defaultPreElement();