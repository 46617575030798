/**
 * Created by srsr on 22-06-2017.
 */
(function () {
  'use strict';

  DGS.useCustomSelect();
  DGS.OnLoad.getInstance().register(function () {
    // ADDS CUSTOM DROP DOWNS FOR SELECTS IN FORMS COMPONENT
    DGS.Event.getInstance().trigger(DGS.Events.SELECT_ADDED, $('.component.form select'));
  });
})(jQuery, window.DGS);