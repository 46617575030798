"use strict";

(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    function onImageLoadComplete($this) {
      window.DGS.Overflow.getInstance().register($this, $('.testimonial-spot-text', $this));
    }

    $('.testimonial-spot').each(function () {
      var $this = $(this),
          $img = $('.testimonial-spot-image img', $this);

      if ($img.length) {
        window.DGS.ImageComplete.getInstance().register($img, function () {
          onImageLoadComplete($this);
        });
      } else {
        $(this).addClass("no-image");
        window.DGS.LoadComplete.getInstance().register($this);
      }
    });
  });
})(jQuery);