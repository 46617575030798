(function ($) {
  "use strict";

  $.fn.sharingTools = function (customOptions) {
    var privateSettings = {
      _jsonServices: "#socialSharingServicesJson",
      _parsedData: null,
      _lookForProperty: "services",
      _serviceContainerClass: "share-services",
      _buildSettings: {},
      _serviceArray: [],
      _customLinks: {
        facebook: function facebook() {
          return "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
        },
        twitter: function twitter() {
          return "http://twitter.com/share";
        },
        gplus: function gplus(link) {
          return "https://plus.google.com/share?url=" + window.location.href;
        },
        linkedin: function linkedin(link) {
          return "https://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href;
        },
        mail: function mail(link) {
          return "mailto:?Subject=" + settings._dictionary.Sharing.mailSubject + "&body=" + window.location.href;
        },
        whatsapp: function whatsapp() {
          return "https://wa.me/?text=" + encodeURIComponent(window.location.href);
        }
      },
      _dictionary: {
        Sharing: {
          headerText: "Share this",
          mailSubject: "I thought you might like this"
        }
      }
    };
    var settings = $.extend({}, $.fn.sharingTools.defaults, customOptions, privateSettings),
        $this = $(this);
    var theLang = $('html').attr('lang');
    var f = {
      // Initialization of the plugin
      init: function init() {
        f.actions.getJson();
        f.actions.iterateOverParsedData();
        DGS.Dictionary.getInstance().getTexts(settings._dictionary, function () {
          f.actions.buildServices();
        }, theLang);

        if (settings.layout === "static") {
          f.actions.moveToPosition();
        } else if (settings.layout === "float" && settings.restrictBoundariesSelectors !== null) {
          f.actions.restrictScroll();
        }
      },
      actions: {
        /**
         * Get json and parse it
         */
        getJson: function getJson() {
          $(settings._jsonServices).each(function () {
            var data = $(this).html();

            try {
              settings._parsedData = JSON.parse(data);
            } catch (e) {
              console.error(e);
            }
          });
        },

        /**
         * Iterate over data and push each service into array
         */
        iterateOverParsedData: function iterateOverParsedData() {
          if (settings._parsedData.hasOwnProperty(settings._lookForProperty)) {
            settings._parsedData[settings._lookForProperty].forEach(function (service) {
              settings._serviceArray.push(f.actions.buildService(service));
            });
          }
        },

        /**
         * return individual service as jQuery object
         */
        buildService: function buildService(service) {
          var cssClass = "";

          if (service.hasOwnProperty('Class') && service.Class != "") {
            cssClass = service.Class;
          } else {
            cssClass = service.Name;
          }

          return $('<span>').addClass(settings.serviceElementClass).append($('<a>').attr("data-appid", service.AppId).attr("href", f.actions.addHref()).addClass(cssClass).text(service.Name));
        },

        /**
         * probably redundant :)
         */
        addHref: function addHref() {
          return "#"; //window.location.href;
        },

        /**
         * Create build settings for Builder and build it
         */
        buildServices: function buildServices() {
          if (settings._serviceArray.length > 0) {
            $this.addClass(settings.layout);
            settings._buildSettings = {
              mainContainer: $this,
              sections: {
                mobile: {
                  containers: [{
                    className: settings._serviceContainerClass,
                    components: [f.actions.getContainerHeader()].concat(settings._serviceArray)
                  }]
                },
                desktop: {
                  containers: [{
                    className: settings._serviceContainerClass,
                    components: [f.actions.getContainerHeader()].concat(settings._serviceArray)
                  }]
                }
              }
            };
            DGS.Builder.build(settings._buildSettings);
            f.actions.customizeLinks();
          }
        },

        /**
         * Create and return header
         */
        getContainerHeader: function getContainerHeader() {
          var headerText = settings._dictionary.Sharing.headerText;
          return $('<div>').addClass(settings.serviceElementsHeaderClass).append($('<span>').text(headerText));
        },

        /**
         * Customize each link with sharing link
         */
        customizeLinks: function customizeLinks() {
          $("." + settings.serviceElementClass).each(function () {
            var $link = $(this).find("a");
            var $class = $link.attr("class").toLowerCase();

            if ($class.indexOf("facebook") >= 0) {
              $link.attr("href", settings._customLinks.facebook).attr("data-sharing-service", "facebook");
            } else if ($class.indexOf("twitter") >= 0) {
              var $pageTitle = $(document).find("title").text().trim().replace(/[|]/g, "-");
              $link.attr("href", settings._customLinks.twitter);
              $link.attr("href", $link.attr("href") + "?text=" + $pageTitle + "&url=" + window.location.href);
              $link.attr("data-sharing-service", "twitter");
            } else if ($class.indexOf("linkedin") >= 0) {
              $link.attr("href", settings._customLinks.linkedin).attr("data-sharing-service", "linkedin");
            } else if ($class.indexOf("google-plus") >= 0) {
              $link.attr("href", settings._customLinks.gplus).attr("data-sharing-service", "google-plus");
            } else if ($class.indexOf("mail") >= 0) {
              $link.attr("href", settings._customLinks.mail).attr("data-sharing-service", "mail");
            } else if ($class.indexOf("whatsapp") >= 0) {
              $link.attr("href", settings._customLinks.whatsapp).attr("data-action", "share/whatsapp/share").attr("data-sharing-service", "whatsapp");
            }

            if (!$class.indexOf("mail") >= 0) {
              $link.attr("target", "_blank");
              $link.click(f.events.openWindowOnClick);
            }
          });
        },

        /**
         * Move to position if layout is static
         */
        moveToPosition: function moveToPosition() {
          $this.insertAfter(settings.insertAfter);
        },

        /**
         * Restricts the scroll from the Y axis of the socialmedia floating box
         */
        restrictScroll: function restrictScroll() {
          // First lets check that the object has the required attributes
          if (typeof settings.restrictBoundariesSelectors.top !== 'undefined' && typeof settings.restrictBoundariesSelectors.bottom !== 'undefined') {
            var $upperBoundary = $(settings.restrictBoundariesSelectors.top),
                $lowerBoundary = $(settings.restrictBoundariesSelectors.bottom); // Now lets check that the elements indeed exists in the DOM

            if ($upperBoundary.length > 0 && $lowerBoundary.length > 0) {
              // If everything is set in order, then hook up the scroll event
              DGS.Scroller.getInstance().register(function () {
                $this.removeClass("overflowing");

                if ($(document).width() < settings.mobileSize) {
                  // Not the prettiest, but that is how it's done on the css
                  $this.removeAttr('style');
                  return;
                }

                $this.css("top", "auto"); // Reset the element to be floating

                var upperLimit = $upperBoundary.offset().top + $upperBoundary.height(),
                    // Gets the bottom of the upper boundary
                lowerLimit = $lowerBoundary.offset().top;

                if ($this.offset().top < upperLimit) {
                  // HITTING UPPER BOUNDARY
                  f.helper.debugConsole("Passing upper boundary");
                  $this.addClass("overflowing");
                  $this.css("top", upperLimit + "px");
                } else if ($this.offset().top + $this.outerHeight() > lowerLimit) {
                  // HITTING LOWER BOUNDARY
                  f.helper.debugConsole("Passing LOWER boundary");
                  $this.addClass("overflowing");
                  $this.css("top", lowerLimit - $this.outerHeight() + "px");
                }
              });
            } else {
              f.helper.debugConsole("The boundary elements are not in the DOM");
            }
          } else {
            f.helper.debugConsole("Undefined restrictedBoundariesSelector, either top OR bottom values");
          }
        }
      },
      events: {
        /**
         * On click, open new window
         */
        openWindowOnClick: function openWindowOnClick(e) {
          var link = $(this);
          e.preventDefault();

          if (settings.trackingEvent !== null) {
            var sharingService = link.attr('data-sharing-service');
            settings.trackingEvent(sharingService);
          }

          window.open(link.attr("href"), 'sharer', settings.shareWindowDimensions);
        }
      },
      helper: {
        debugConsole: function debugConsole(string) {
          if (settings.debugMode === true) {
            console.log(string);
          }
        }
      }
    };
    return this.each(function () {
      f.init();
    });
  }; // Public defaults


  $.fn.sharingTools.defaults = {
    layout: "float",
    //float or static
    insertAfter: ".footer",
    restrictBoundariesSelectors: null,
    // OBJECT with the TOP and BOTTOM selector boundaries. Set to null for unrestricted boundaries
    serviceElementsHeaderClass: "service-elements-header",
    //header class
    serviceElementClass: "service-element",
    //wrapper class for each service
    shareWindowDimensions: "width=626,height=436",
    //dimensions of popup
    debugMode: false,
    // Enable to print console.log messages. Not recommended for production environments
    mobileSize: 992,
    // Size (in pixel) of the mobile view,
    trackingEvent: null
  };
})(jQuery);